import React from "react";
import { Route, Switch } from "react-router-dom";
import { Toolbar } from "@material-ui/core";
import HeaderAppBar from "./HeaderAppBar";
import NavigationDrawer from "./NavigationDrawer";
import Campaign from "../pages/campaigns/Campaign";
import Campaigns from "../pages/campaigns/Campaigns";
import NewCampaign from "../pages/campaigns/NewCampaign";
import Lists from "../pages/lists/Lists";
import NewList from "../pages/lists/NewList";
import Surveys from "../pages/surveys/Surveys";
import NewSurvey from "../pages/surveys/NewSurvey";
import Universes from "../pages/universes/Universes";
import NewUniverse from "../pages/universes/NewUniverse";
import Users from "../pages/users/Users";
import User from "../pages/users/User";
import NewUser from "../pages/users/NewUser";
import usePageViews from "./hooks/usePageViews";
import styles from "./App.module.css";
import ListView from "../pages/lists/ListView";
import SurveyView from "../pages/surveys/SurveyView";
import UniverseView from "../pages/universes/UniverseView";
import Home from "./Home";

function App() {
  usePageViews();

  return (
    <div className={styles.appContainer}>
      <HeaderAppBar />
      <NavigationDrawer />
      <main className={styles.mainContainer}>
        <Toolbar />
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/campaigns" exact>
            <Campaigns />
          </Route>
          <Route path="/campaigns/new" exact component={NewCampaign} />
          <Route path="/campaigns/:id" component={Campaign} />
          <Route path="/lists" exact>
            <Lists />
          </Route>
          <Route path="/lists/new" exact component={NewList} />
          <Route path="/lists/:id" component={ListView} />
          <Route path="/surveys" exact>
            <Surveys />
          </Route>
          <Route path="/surveys/new" exact component={NewSurvey} />
          <Route path="/surveys/:id" component={SurveyView} />
          <Route path="/universes" exact>
            <Universes />
          </Route>
          <Route path="/universes/new" exact component={NewUniverse} />
          <Route path="/universes/:id" component={UniverseView} />
          <Route path="/users" exact>
            <Users />
          </Route>
          <Route path="/users/new" exact component={NewUser} />
          <Route path="/users/:id" component={User} />
        </Switch>
      </main>
    </div>
  );
}

export default App;
