import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Toolbar,
  Typography,
  Icon,
  IconButton,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import CancelButton from "../../components/buttons/CancelButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import CampaignField from "./CampaignField";
import {
  addAppRoleAssignment,
  addGroupMember,
  createInvitation,
  getUserAppRoleAssignments,
  getUserMemberOfGroups,
  getUsers,
} from "../../services/GraphService";
import useLocationSearch from "../../core/hooks/useLocationSearch";
import styles from "./NewUser.module.css";
import AppRoleGranted from "../../security/AppRoleGranted";
import AppRole from "../../security/AppRole";
import { AppRoles, getAppRoleLabel } from "../../security/AppRoles";
import { Grantee } from "../../store/models/Grantee";
import GranteeType from "../../store/models/GranteeType";
import DigitalDocumentPermissionType from "../../store/models/DigitalDocumentPermissionType";
import { DigitalDocumentPermission } from "../../store/models/DigitalDocumentPermission";
import { GroupMember } from "../../store/models/GroupMember";
import { IJsonapiModel } from "@datx/jsonapi";
import useAccountInfo from "../../security/useAccountInfo";
import useApiPermissionGranted from "../../security/useApiPermissionGranted";
import ApiPermission from "../../security/ApiPermission";
import { parseCommaSeparatedValues } from "../../services/UserParserService";
import { ApplicationUser } from "../../store/models/ApplicationUser";
import AccountStatus from "../../store/models/AccountStatus";
import ProcessingDialog from "../../components/dialogs/ProcessingDialog";

const EMAIL_PATTERN = new RegExp("[^@]+?@[^@]+");

function NewUser() {
  const locationSearch = useLocationSearch();
  let searchGroupId = locationSearch.get("groupId");
  const initialGroupId = searchGroupId ? searchGroupId : undefined;
  const accountInfo = useAccountInfo();

  const groupPermissionGranted = useApiPermissionGranted(
    ApiPermission.GROUP_READ_WRITE_ALL
  );

  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [sendInvitation, setSendInvitation] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [appRoleIds, setAppRoleIds] = useState<string[]>([]);
  const [applicationUsers, setApplicationUsers] = useState<ApplicationUser[]>(
    []
  );
  const [completed, setCompleted] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string>();
  const [usersCompleted, setUsersCompleted] = useState(0);
  const [usersFailed, setUsersFailed] = useState(0);
  const [processingOpen, setProcessingOpen] = useState(false);
  const [processingCompleted, setProcessingCompleted] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onSendInvitationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSendInvitation(event.target.checked);
  };

  const onAppRoleChange = (
    event: ChangeEvent<HTMLInputElement>,
    appRole: AppRole
  ) => {
    const appRoleId = AppRoles.get(appRole);
    if (appRoleId) {
      if (event.target.checked) {
        const newAppRoleIds = [...appRoleIds, appRoleId];
        setAppRoleIds(newAppRoleIds);
      } else {
        const newAppRoleIds = appRoleIds.filter(
          (currentAppRoleId) => currentAppRoleId !== appRoleId
        );
        setAppRoleIds(newAppRoleIds);
      }
    }
  };

  const isAppRoleChecked = (appRole: AppRole) => {
    let checked = false;
    const appRoleId = AppRoles.get(appRole);
    if (appRoleId) {
      checked = appRoleIds.includes(appRoleId);
    }
    return checked;
  };

  const getResourceId = async () => {
    const localAccountId = accountInfo!.localAccountId;
    const userAppRoleAssignments = await getUserAppRoleAssignments(
      localAccountId
    );
    return userAppRoleAssignments[0]?.resourceId;
  };

  const submitSingleUser = async () => {
    if (email) {
      const userId = await getNewUserId(email, sendInvitation, name);
      if (userId) {
        await setGroupMember(userId, groupId);

        if (appRoleIds.length) {
          const resourceId = await getResourceId();
          if (resourceId) {
            const addAppRolePromises = appRoleIds.map((appRoleId) => {
              return addAppRoleAssignment(userId, resourceId, appRoleId);
            });
            await Promise.all(addAppRolePromises);
          }
        }

        setUsersCompleted(1);
      } else {
        setUsersFailed(1);
      }
    }

    setProcessingCompleted(true);
  };

  const submitParsedUsers = async () => {
    const idPromises = applicationUsers.map((applicationUser) => {
      const promise = getNewUserId(
        applicationUser.username,
        sendInvitation,
        applicationUser.displayName
      );
      promise.then(
        (id) => {
          if (id) {
            applicationUser.id = id;
            applicationUser.accountStatus = AccountStatus.PENDING_ACCEPTANCE;
          }
        },
        () => {
          applicationUser.accountStatus = AccountStatus.DISABLED;
        }
      );
      return promise;
    });
    await Promise.allSettled(idPromises);

    const memberPromises = applicationUsers
      .filter(
        (applicationUser) =>
          applicationUser.accountStatus === AccountStatus.PENDING_ACCEPTANCE
      )
      .map((applicationUser) => {
        const promise = setGroupMember(applicationUser.id, groupId);
        promise.then(
          () => {
            setUsersCompleted((usersCompleted) => usersCompleted + 1);
          },
          () => {
            setUsersFailed((usersFailed) => usersFailed + 1);
          }
        );
        return promise;
      });
    await Promise.allSettled(memberPromises);

    setProcessingCompleted(true);
  };

  const onSubmitClick = async () => {
    setUsersCompleted(0);
    setUsersFailed(0);
    setSubmitDisabled(true);
    setProcessingOpen(true);
    try {
      if (applicationUsers.length) {
        submitParsedUsers();
      } else {
        submitSingleUser();
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Submit Failed");
      setErrorMessage("Processing failed");
      setErrorOpen(true);
    } finally {
      setSubmitDisabled(false);
    }
  };

  const onProcessingDialogClose = () => {
    setProcessingOpen(false);
    setCompleted(true);
  };

  const setGroupMember = async (userId: string, groupId?: string) => {
    if (groupId) {
      const memberGroups = await getUserMemberOfGroups(userId);
      const foundMemberGroup = memberGroups.find(
        (group) => groupId === group.id
      );
      if (foundMemberGroup === undefined) {
        if (groupPermissionGranted) {
          await addGroupMember(groupId, userId);
        } else {
          await createGroupMember(groupId, userId);
        }
      }
    }
  };

  const getGroupPermission = (groupId: string) => {
    const groupGrantee = new Grantee();
    groupGrantee.id = groupId;
    groupGrantee.name = groupId;
    groupGrantee.granteeType = GranteeType.GROUP;

    const groupPermission = new DigitalDocumentPermission();
    groupPermission.grantee = groupGrantee;
    groupPermission.permissionType = DigitalDocumentPermissionType.WRITE;

    return groupPermission;
  };

  const createGroupMember = (
    groupId: string,
    directoryObjectId: string
  ): Promise<IJsonapiModel> => {
    const groupPermission = getGroupPermission(groupId);
    const groupMember = new GroupMember({
      directoryObjectId: directoryObjectId,
      permissions: [groupPermission],
    });
    return groupMember.save();
  };

  const getNewUserId = async (
    email: string,
    sendInvitation: boolean,
    name?: string
  ) => {
    let userId: string | undefined = undefined;

    const foundUsers = await getUsers(email);
    if (foundUsers.length) {
      const foundUser = foundUsers[0];
      userId = foundUser.id;
    } else {
      const invitation = await createInvitation(email, name, sendInvitation);
      if (invitation.invitedUser) {
        userId = invitation.invitedUser.id;
      }
    }

    return userId;
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onCampaignChange = (campaignGroupId: string) => {
    setGroupId(campaignGroupId);
  };

  const onGroupsFound = (groupsFound: number) => {};

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileSelected = event.target.files[0];
      if (fileSelected) {
        parseCommaSeparatedValues(fileSelected, onParseComplete);
      }
    }
  };

  const onParseComplete = (data: object[], errors: object) => {
    const parsedUsers = data
      .map((record, index) => {
        const user = new ApplicationUser();

        if (Array.isArray(record)) {
          user.username = record[0];
          user.displayName = record[1];
        }

        return user;
      })
      .filter((user) => EMAIL_PATTERN.test(user.username));

    if (parsedUsers.length) {
      setApplicationUsers(parsedUsers);
      setUploadMessage(`Users Uploaded: ${parsedUsers.length}`);
    } else {
      setApplicationUsers([]);
      setUploadMessage("No Users Found");
    }
  };

  useEffect(() => {
    if (email?.length || applicationUsers.length) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [email, applicationUsers.length]);

  if (completed) {
    return <Redirect to="/users" />;
  }

  const appRoleControls: ReactElement[] = [];
  AppRoles.forEach((appRoleId, appRole) => {
    appRoleControls.push(
      <AppRoleGranted appRole={appRole} key={appRoleId}>
        <FormControlLabel
          label={getAppRoleLabel(appRole)}
          control={
            <Checkbox
              color="primary"
              checked={isAppRoleChecked(appRole)}
              onChange={(event) => onAppRoleChange(event, appRole)}
            />
          }
        />
      </AppRoleGranted>
    );
  });

  return (
    <div>
      <Toolbar disableGutters>
        <IconButton disabled>
          <PrimaryAvatar>
            <Icon>person</Icon>
          </PrimaryAvatar>
        </IconButton>
        <Typography variant="h6" className={styles.headerLabel}>
          New User
        </Typography>
        <Typography variant="body2">{uploadMessage}</Typography>
        <label htmlFor="upload" className={styles.uploadLabel}>
          <input
            className={styles.fileInput}
            id="upload"
            name="upload"
            type="file"
            accept="text/csv"
            onChange={onFileChange}
          />
          <Button component="span" variant="contained">
            Upload
          </Button>
        </label>
      </Toolbar>
      <form autoComplete="off">
        <div className={styles.field}>
          <CampaignField
            onCampaignChangeHandler={onCampaignChange}
            onGroupsFound={onGroupsFound}
            initialGroupId={initialGroupId}
          />
        </div>
        {applicationUsers.length ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicationUsers.map((applicationUser) => (
                  <TableRow key={applicationUser.username}>
                    <TableCell>{applicationUser.username}</TableCell>
                    <TableCell>{applicationUser.displayName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <TextField
              label="Name"
              variant="outlined"
              disabled={processingOpen}
              fullWidth
              onChange={onNameChange}
              className={styles.field}
            />
            <TextField
              label="Email"
              variant="outlined"
              type="email"
              disabled={processingOpen}
              fullWidth
              onChange={onEmailChange}
            />
          </>
        )}
        <FormControl component="fieldset" className={styles.checkboxField}>
          <FormLabel component="legend" className={styles.checkboxField}>
            Application Roles
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              label="List-Read"
              control={
                <Checkbox color="primary" name="list-read" checked disabled />
              }
            />
            {applicationUsers.length === 0 && appRoleControls}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={styles.checkboxField}>
          <FormLabel component="legend" className={styles.checkboxField}>
            Notification Settings
          </FormLabel>
          <FormControlLabel
            label="Send Invitation"
            disabled={processingOpen}
            control={
              <Checkbox
                color="primary"
                name="sendInvitation"
                checked={sendInvitation}
                onChange={onSendInvitationChange}
              />
            }
          />
        </FormControl>
      </form>
      <Toolbar disableGutters>
        <Grid container justifyContent="flex-end">
          <CancelButton buttonLink="/users" />
          <SubmitButton onClick={onSubmitClick} disabled={submitDisabled} />
        </Grid>
      </Toolbar>
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      <ProcessingDialog
        title="Processing Users"
        message={`Completed: ${usersCompleted} and Failed: ${usersFailed}`}
        open={processingOpen}
        completed={processingCompleted}
        onClose={onProcessingDialogClose}
      />
    </div>
  );
}

export default NewUser;
