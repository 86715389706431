import { createTheme } from "@material-ui/core/styles";

const AppTheme = createTheme({
  palette: {
    primary: {
      main: "#002e6dff",
    },
    secondary: {
      main: "#ce0e2D",
    },
    background: {
      default: "#fff",
    },
  },
});

export default AppTheme;
