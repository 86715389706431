import useSectionHeading from "./useSectionHeading";

const applicationHeading = "Trireme";

const useApplicationHeading = () => {
  const sectionHeading = useSectionHeading();
  let pageHeading = applicationHeading;
  if (sectionHeading) {
    pageHeading = sectionHeading;
  }
  return pageHeading;
};

export default useApplicationHeading;
