enum ApiPermission {
  DIRECTORY_ACCESS_AS_USER_ALL = "Directory.AccessAsUser.All",
  GROUP_READ_WRITE_ALL = "Group.ReadWrite.All",
  GROUP_MEMBER_READ_ALL = "GroupMember.Read.All",
  GROUP_MEMBER_READ_WRITE_ALL = "GroupMember.ReadWrite.All",
  USER_INVITE_ALL = "User.Invite.All",
  USER_READ = "User.Read",
  USER_READ_WRITE_ALL = "User.ReadWrite.All",
}

export default ApiPermission;
