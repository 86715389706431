import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Icon,
  Link,
} from "@material-ui/core";
import styles from "./UniverseCard.module.css";
import { Universe } from "../../store/models/Universe";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";
import GranteeType from "../../store/models/GranteeType";
import GroupPermissionButton from "../../components/GroupPermissionButton";
import ScheduleChip from "../../components/ScheduleChip";
import StatusAvatar from "../../components/StatusAvatar";
import AppRoleGranted from "../../security/AppRoleGranted";
import StatusSwitch from "../../components/StatusSwitch";
import AppRole from "../../security/AppRole";

type Props = {
  universe: Universe;
  onChange: (universe: Universe) => void;
};

function UniverseCard({ universe, onChange }: Props) {
  const [creativeWorkStatus, setCreativeWorkStatus] = useState(
    universe.creativeWorkStatus
  );

  const getQuantitativeValue = (universe: Universe, name: string) => {
    const quantitativeValue = universe.quantitativeValues.find(
      (quantitativeValue) => quantitativeValue.name === name
    );
    return quantitativeValue ? quantitativeValue.value : 0;
  };

  const onChangeStatus = (status: CreativeWorkStatus) => {
    setCreativeWorkStatus(status);
    universe.creativeWorkStatus = status;
    onChange(universe);
  };

  const userPermission = universe.permissions.find(
    (permission) => permission.grantee.granteeType === GranteeType.USER
  );
  const username = userPermission?.grantee.name;
  const landValues = getQuantitativeValue(universe, "Land");
  const cellValues = getQuantitativeValue(universe, "Cell");
  const democratValue = getQuantitativeValue(universe, "D");
  const republicanValue = getQuantitativeValue(universe, "R");
  const independentValue = getQuantitativeValue(universe, "I");
  const ageValue = getQuantitativeValue(universe, "age");

  return (
    <Card className={styles.universeCard}>
      <CardHeader
        title={
          <Link component={RouterLink} to={`/universes/${universe.id}`}>
            {universe.name}
          </Link>
        }
        subheader={
          <div>
            <Chip
              label={universe.voters}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">person</Icon>}
            />
            <Chip
              label={universe.households}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">home</Icon>}
            />
            <Chip
              label={landValues}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">phone</Icon>}
            />
            <Chip
              label={cellValues}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">smartphone</Icon>}
            />
            <Chip
              label={democratValue}
              className={styles.chip}
              size="small"
              variant="outlined"
              avatar={<Avatar>D</Avatar>}
            />
            <Chip
              label={independentValue}
              className={styles.chip}
              size="small"
              variant="outlined"
              avatar={<Avatar>I</Avatar>}
            />
            <Chip
              label={republicanValue}
              className={styles.chip}
              size="small"
              variant="outlined"
              avatar={<Avatar>R</Avatar>}
            />
            <Chip
              label={ageValue}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">cake</Icon>}
            />
            <ScheduleChip dateTime={universe.dateCreated} />
            <Chip
              label={username}
              className={styles.secondaryChip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">account_circle</Icon>}
            />
          </div>
        }
        avatar={
          <StatusAvatar creativeWorkStatus={creativeWorkStatus} icon="groups" />
        }
      />
      <CardActions>
        <div className={styles.actionsContainer}>
          <GroupPermissionButton permissions={universe.permissions} />
        </div>
        <AppRoleGranted appRole={AppRole.UNIVERSE_READ_WRITE}>
          <StatusSwitch
            creativeWorkStatus={creativeWorkStatus}
            onChangeStatus={onChangeStatus}
          />
        </AppRoleGranted>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          disabled={creativeWorkStatus === CreativeWorkStatus.ARCHIVED}
          to={`/lists/new?universeId=${universe.id}`}
        >
          New List
        </Button>
      </CardActions>
    </Card>
  );
}

export default UniverseCard;
