import { GeoCoordinates } from "../store/models/voter/GeoCoordinates";

const DEGREES_TO_RADIANS = Math.PI / 180;
const HAVERSINE_EXPONENT = 2;
const EARTH_RADIUS_KM = 6371.0088;

function getRadians(degrees: number) {
  return degrees * DEGREES_TO_RADIANS;
}

function getHaversine(degrees: number) {
  const radians = getRadians(degrees);
  const halfRadians = radians / HAVERSINE_EXPONENT;
  const halfRadiansSine = Math.sin(halfRadians);
  return Math.pow(halfRadiansSine, HAVERSINE_EXPONENT);
}

export function getHaversineDistance(
  firstGeo: GeoCoordinates,
  secondGeo: GeoCoordinates
) {
  const firstLat = firstGeo.latitude;
  const firstLng = firstGeo.longitude;

  const secondLat = secondGeo.latitude;
  const secondLng = secondGeo.longitude;

  const differenceLat = firstLat - secondLat;
  const differenceLng = firstLng - secondLng;

  const haversineLat = getHaversine(differenceLat);
  const haversineLng = getHaversine(differenceLng);

  const firstLatRadians = getRadians(firstLat);
  const firstLatCos = Math.cos(firstLatRadians);

  const secondLatRadians = getRadians(secondLat);
  const secondLatCos = Math.cos(secondLatRadians);

  const a = haversineLat + haversineLng * firstLatCos * secondLatCos;
  const squareRoot = Math.sqrt(a);
  const arcsine = Math.asin(squareRoot);
  const c = HAVERSINE_EXPONENT * arcsine;

  return EARTH_RADIUS_KM * c;
}
