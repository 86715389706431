import { Answer } from "./Answer";
import QuestionType from "./QuestionType";
import QuestionPurposeType from "./QuestionPurposeType";

export class Question {
  public id!: string;

  public name!: string;

  public version: number = 1;

  public questionType: QuestionType = QuestionType.SINGLE_ANSWER;

  public questionPurposeType: QuestionPurposeType = QuestionPurposeType.SURVEY;

  public dateCreated!: string;

  public datePublished!: string;

  public answers!: Array<Answer>;
}
