enum AppRole {
  APP_ROLE_ASSIGNMENT_READ_WRITE = "AppRoleAssignment.ReadWrite",
  GROUP_MEMBER_READ_WRITE = "GroupMember.ReadWrite",
  LIST_READ_WRITE = "List.ReadWrite",
  SURVEY_READ_WRITE = "Survey.ReadWrite",
  UNIVERSE_READ = "Universe.Read",
  UNIVERSE_READ_WRITE = "Universe.ReadWrite",
}

export default AppRole;
