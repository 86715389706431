import { Configuration } from "@azure/msal-browser";

const msalConfiguration: Configuration = {
  auth: {
    clientId: "51ace9ef-739a-4fbe-90b2-e1cad53b8188",
    authority:
      "https://login.microsoftonline.com/29bdf4bb-571c-4bbd-8712-30ecafa49ae2",
  },
};

export default msalConfiguration;
