import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import useCollections from "../../store/useCollections";
import { Survey } from "../../store/models/Survey";
import AppRole from "../../security/AppRole";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import withAuthorization from "../../security/withAuthorization";
import SurveyCard from "./SurveyCard";
import LoadingProgress from "../../components/LoadingProgress";
import useLocationSearch from "../../core/hooks/useLocationSearch";
import { getRequestOptions } from "../../services/ResourceSearchService";
import styles from "./Surveys.module.css";
import CampaignField from "../users/CampaignField";

function Surveys() {
  const locationSearch = useLocationSearch();
  let searchGroupId = locationSearch.get("groupId");
  const initialGroupId = searchGroupId ? searchGroupId : undefined;

  const collections = useCollections();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onSearchChangeHandler = (requestedSearch: string) => {
    if (requestedSearch.length) {
      setSearch(requestedSearch);
    } else {
      setSearch(undefined);
    }
  };

  const onCampaignChange = (campaignGroupId: string) => {
    setGroupId(campaignGroupId);
  };

  const onGroupsFound = (groupsFound: number) => {
    if (groupsFound === 0) {
      setLoading(false);
    }
  };

  const getSurveys = useCallback(async () => {
    setLoading(true);
    try {
      const options = getRequestOptions(search, groupId);
      const response = await collections.getMany(Survey, options);
      if (Array.isArray(response.data)) {
        setSurveys(response.data);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Loading Failed");
      setErrorMessage("Processing Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }, [collections, search, groupId]);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onChange = async (survey: Survey) => {
    try {
      await survey.save();
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Save Failed");
      setErrorMessage(`Survey Status update failed`);
      setErrorOpen(true);
    }
  };

  useEffect(() => {
    if (groupId) {
      withAuthorization(getSurveys, () => {
        setErrorTitle("Authorization Failed");
        setErrorMessage("Unable to get Access Token");
        setErrorOpen(true);
      });
    }
  }, [getSurveys, collections, search, groupId]);

  return (
    <div>
      <SearchBar
        addButtonLink={"/surveys/new"}
        total={surveys.length}
        onSearchChangeHandler={onSearchChangeHandler}
        addAppRoleRequired={AppRole.SURVEY_READ_WRITE}
      />
      <div className={styles.campaignField}>
        <CampaignField
          onCampaignChangeHandler={onCampaignChange}
          onGroupsFound={onGroupsFound}
          initialGroupId={initialGroupId}
        />
      </div>
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      {surveys.map((survey) => (
        <SurveyCard key={survey.id} survey={survey} onChange={onChange} />
      ))}
    </div>
  );
}

export default Surveys;
