import React, { PropsWithChildren } from "react";
import AppRole from "./AppRole";
import useAppRoleGranted from "./useAppRoleGranted";

type AppRoleRequired = {
  appRole: AppRole;
};

type AppRoleGrantedProps = PropsWithChildren<AppRoleRequired>;

function AppRoleGranted({
  appRole,
  children,
}: AppRoleGrantedProps): React.ReactElement | null {
  const appRoleGranted = useAppRoleGranted(appRole);

  if (appRoleGranted) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
}

export default AppRoleGranted;
