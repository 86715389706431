import React, { useCallback, useEffect, useState, ChangeEvent } from "react";
import { Redirect } from "react-router";
import {
  TextField,
  Toolbar,
  Typography,
  Icon,
  IconButton,
  Button,
  Grid,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import CancelButton from "../../components/buttons/CancelButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import QuestionCard from "./QuestionCard";
import { parseCommaSeparatedValues } from "../../services/SurveyParserService";
import useCollections from "../../store/useCollections";
import withAuthorization from "../../security/withAuthorization";
import { Survey } from "../../store/models/Survey";
import { Question } from "../../store/models/Question";
import { Answer } from "../../store/models/Answer";
import styles from "./NewSurvey.module.css";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";
import useAccountInfo from "../../security/useAccountInfo";
import { Grantee } from "../../store/models/Grantee";
import { DigitalDocumentPermission } from "../../store/models/DigitalDocumentPermission";
import DigitalDocumentPermissionType from "../../store/models/DigitalDocumentPermissionType";
import GranteeType from "../../store/models/GranteeType";
import CampaignField from "../users/CampaignField";
import ModelVersion from "../../store/models/ModelVersion";
import { saveCommaSeparatedValues } from "../../services/FileDownloadService";

function NewSurvey() {
  const collections = useCollections();
  const accountInfo = useAccountInfo();

  const question = new Question();
  question.id = uuid();
  question.dateCreated = new Date().toISOString();
  question.datePublished = new Date().toISOString();

  const answer = new Answer();
  answer.id = uuid();
  answer.dateCreated = new Date().toISOString();
  answer.datePublished = new Date().toISOString();
  question.answers = [answer];

  const [groupId, setGroupId] = useState<string>();
  const [groupName, setGroupName] = useState<string>();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [paragraphs, setParagraphs] = useState<string[]>([]);
  const [questions, setQuestions] = useState([question]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const getGroupGrantee = useCallback(() => {
    const groupGrantee = new Grantee();
    if (groupId && groupName) {
      groupGrantee.id = groupId;
      groupGrantee.name = groupName;
      groupGrantee.granteeType = GranteeType.GROUP;
    }
    return groupGrantee;
  }, [groupId, groupName]);

  const onCampaignChange = (
    campaignGroupId: string,
    campaignGroupName: string
  ) => {
    setGroupId(campaignGroupId);
    setGroupName(campaignGroupName);
  };

  const onGroupsFound = (groupsFound: number) => {};

  const onAddQuestion = (questionNumber: number) => {
    const question = new Question();
    question.id = uuid();
    question.dateCreated = new Date().toISOString();
    question.datePublished = new Date().toISOString();

    const answer = new Answer();
    answer.dateCreated = new Date().toISOString();
    answer.datePublished = new Date().toISOString();
    answer.id = uuid();
    question.answers = [answer];

    const currentQuestions = [...questions];
    currentQuestions.splice(questionNumber, 0, question);
    setQuestions(currentQuestions);
  };

  const onRemoveQuestion = (questionId: string) => {
    const updatedQuestions = questions.filter((question) => {
      return question.id !== questionId;
    });
    setQuestions(updatedQuestions);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const onScriptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lines = event.target.value.split("\n");
    const linesTrimmed = lines.map((line) => line.trim());
    setParagraphs(linesTrimmed);
  };

  const onDownloadClick = () => {
    const records = questions.map((question) => {
      const record = [];
      record.push(question.name);

      question.answers.forEach((answer) => {
        record.push(answer.name);
      });

      return record;
    });
    saveCommaSeparatedValues(records, undefined, name);
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileSelected = event.target.files[0];
      if (fileSelected) {
        parseCommaSeparatedValues(fileSelected, onParseComplete);
      }
    }
  };

  const onParseComplete = (data: object[], errors: object) => {
    const parsedQuestions = data.map((record, index) => {
      const question = new Question();
      question.id = uuid();
      question.dateCreated = new Date().toISOString();
      question.datePublished = new Date().toISOString();

      if (Array.isArray(record)) {
        question.name = record[0];

        const answerNames = record
          .slice(1)
          .filter((answerName) => answerName.length);
        question.answers = answerNames.map((answerName) => {
          const answer = new Answer();
          answer.dateCreated = new Date().toISOString();
          answer.datePublished = new Date().toISOString();
          answer.id = uuid();
          answer.name = answerName;
          return answer;
        });
      }

      return question;
    });

    if (parsedQuestions.length) {
      setQuestions(parsedQuestions);
    }
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const getUserPermission = useCallback(() => {
    const userGrantee = new Grantee();
    userGrantee.id = accountInfo!.localAccountId;
    userGrantee.name = accountInfo!.username;
    userGrantee.granteeType = GranteeType.USER;

    const userPermission = new DigitalDocumentPermission();
    userPermission.grantee = userGrantee;
    userPermission.permissionType = DigitalDocumentPermissionType.WRITE;

    return userPermission;
  }, [accountInfo]);

  const getPermissions = useCallback(() => {
    const userPermission = getUserPermission();
    const groupPermission = new DigitalDocumentPermission();
    groupPermission.grantee = getGroupGrantee();
    groupPermission.permissionType = DigitalDocumentPermissionType.READ;

    return [userPermission, groupPermission];
  }, [getGroupGrantee, getUserPermission]);

  const saveSurvey = useCallback(async () => {
    try {
      const permissions = getPermissions();

      const survey = new Survey({
        id: uuid(),
        version: ModelVersion.SURVEY,
        name: name,
        description: description,
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        creativeWorkStatus: CreativeWorkStatus.PUBLISHED,
        permissions: permissions,
        questions: questions,
        script: {
          paragraphs: paragraphs,
        },
      });

      collections.add(survey);
      await survey.save();
      setCompleted(true);
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Submit Failed");
      setErrorMessage("Processing failed");
      setErrorOpen(true);
    } finally {
      setSubmitDisabled(false);
    }
  }, [collections, name, description, questions, paragraphs, getPermissions]);

  const onSubmitClick = () => {
    withAuthorization(saveSurvey, () => {
      setErrorTitle("Authorization Failed");
      setErrorMessage("Unable to get Access Token");
      setErrorOpen(true);
      setSubmitDisabled(false);
    });
  };

  useEffect(() => {
    if (name?.length) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [name]);

  if (completed) {
    return <Redirect to="/surveys" />;
  }

  return (
    <div>
      <Toolbar disableGutters>
        <IconButton disabled>
          <PrimaryAvatar>
            <Icon>question_answer</Icon>
          </PrimaryAvatar>
        </IconButton>
        <Typography variant="h6" className={styles.headerLabel}>
          New Survey
        </Typography>
        <Button
          component="span"
          variant="contained"
          disabled={name === undefined || name.length === 0}
          onClick={onDownloadClick}
        >
          Download
        </Button>
        <label htmlFor="upload" className={styles.uploadLabel}>
          <input
            className={styles.fileInput}
            id="upload"
            name="upload"
            type="file"
            accept="text/csv"
            onChange={onFileChange}
          />
          <Button component="span" variant="contained">
            Upload
          </Button>
        </label>
      </Toolbar>
      <form autoComplete="off">
        <div className={styles.field}>
          <CampaignField
            onCampaignChangeHandler={onCampaignChange}
            onGroupsFound={onGroupsFound}
          />
        </div>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          className={styles.field}
          onChange={onNameChange}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          className={styles.field}
          onChange={onDescriptionChange}
        />
        <TextField
          label="Script"
          variant="outlined"
          fullWidth
          multiline
          minRows={10}
          className={styles.field}
          onChange={onScriptChange}
        />
      </form>
      {questions.map((question, index) => (
        <QuestionCard
          key={question.id}
          question={question}
          questionId={question.id}
          questionNumber={index + 1}
          className={styles.questionCard}
          removeDisabled={questions.length === 1}
          onAddQuestion={onAddQuestion}
          onRemoveQuestion={onRemoveQuestion}
        />
      ))}
      <Toolbar disableGutters>
        <Grid container justifyContent="flex-end">
          <CancelButton buttonLink="/surveys" />
          <SubmitButton onClick={onSubmitClick} disabled={submitDisabled} />
        </Grid>
      </Toolbar>
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
    </div>
  );
}

export default NewSurvey;
