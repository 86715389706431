import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Icon,
  Link,
} from "@material-ui/core";
import styles from "./ListCard.module.css";
import GroupPermissionButton from "../../components/GroupPermissionButton";
import ListUsersDialog from "./ListUsersDialog";
import { List } from "../../store/models/List";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";
import GranteeType from "../../store/models/GranteeType";
import DigitalDocumentPermissionType from "../../store/models/DigitalDocumentPermissionType";
import AppRoleGranted from "../../security/AppRoleGranted";
import AppRole from "../../security/AppRole";
import ScheduleChip from "../../components/ScheduleChip";
import StatusSwitch from "../../components/StatusSwitch";
import StatusAvatar from "../../components/StatusAvatar";

type Props = {
  list: List;
  onChange: (list: List) => void;
};

function ListCard({ list, onChange }: Props) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [creativeWorkStatus, setCreativeWorkStatus] = useState(
    list.creativeWorkStatus
  );

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  const onUsersClick = () => {
    setDialogOpen(true);
  };

  const onChangeStatus = (status: CreativeWorkStatus) => {
    setCreativeWorkStatus(status);
    list.creativeWorkStatus = status;
    onChange(list);
  };

  const getGroupId = () => {
    const groupPermission = list.permissions.find((permission) => {
      return (
        permission.grantee.granteeType === GranteeType.GROUP &&
        permission.permissionType === DigitalDocumentPermissionType.READ
      );
    });
    return groupPermission!.grantee.id;
  };

  return (
    <Card className={styles.listCard}>
      <CardHeader
        title={
          <Link component={RouterLink} to={`/lists/${list.id}`}>
            {list.name}
          </Link>
        }
        avatar={
          <StatusAvatar
            creativeWorkStatus={creativeWorkStatus}
            icon="assignment"
          />
        }
        subheader={
          <div>
            <Chip
              label={list.totalVoterCount}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">person</Icon>}
            />
            <Chip
              label={list.totalHouseholdCount}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">home</Icon>}
            />
            <ScheduleChip label="Created" dateTime={list.dateModified} />
          </div>
        }
      />
      <CardActions>
        <div className={styles.actionsContainer}>
          <AppRoleGranted appRole={AppRole.LIST_READ_WRITE}>
            <Button
              variant="contained"
              startIcon={<Icon>account_circle</Icon>}
              onClick={onUsersClick}
            >
              Users
            </Button>
          </AppRoleGranted>
          <GroupPermissionButton permissions={list.permissions} />
        </div>
        <AppRoleGranted appRole={AppRole.LIST_READ_WRITE}>
          <StatusSwitch
            creativeWorkStatus={creativeWorkStatus}
            onChangeStatus={onChangeStatus}
          />
        </AppRoleGranted>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/lists/${list.id}`}
        >
          View
        </Button>
      </CardActions>
      <ListUsersDialog
        open={dialogOpen}
        onClose={onDialogClose}
        list={list}
        groupId={getGroupId()}
        creativeWorkStatus={creativeWorkStatus}
      />
    </Card>
  );
}

export default ListCard;
