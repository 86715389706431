import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import {
  Icon,
  IconButton,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import AccountName from "../components/AccountName";
import AccountUsername from "../components/AccountUsername";

function getAccountId(application: IPublicClientApplication) {
  const accounts = application.getAllAccounts();
  const account = accounts.pop();
  return account?.localAccountId;
}

function AccountMenu() {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutCompleted, setLogoutCompleted] = useState<boolean>(false);
  const accountId = getAccountId(instance);

  const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onSignOutClick = (application: IPublicClientApplication) => {
    application.logoutRedirect({
      onRedirectNavigate: (url) => {
        setLogoutCompleted(true);
        return false;
      },
    });
  };

  if (logoutCompleted) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <IconButton
        aria-controls="account-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={onButtonClick}
      >
        <Icon>account_circle</Icon>
      </IconButton>
      <Menu
        id="account-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem divider component={Link} to={`/users/${accountId}`}>
          <ListItemAvatar>
            <Avatar>
              <Icon>person</Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary={<AccountUsername />}>
            <AccountName />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onSignOutClick(instance)}>
          <ListItemIcon>
            <Icon>logout</Icon>
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AccountMenu;
