import React, { ChangeEvent, useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
  TextField,
  Toolbar,
  Typography,
  Icon,
  IconButton,
  Grid,
} from "@material-ui/core";
import CancelButton from "../../components/buttons/CancelButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import { createGroup } from "../../services/GraphService";
import styles from "./NewCampaign.module.css";

function NewCampaign() {
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length) {
      setDescription(value);
    } else {
      setDescription(undefined);
    }
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onSubmitClick = async () => {
    if (name) {
      setSubmitDisabled(true);
      setLoading(true);
      try {
        await createGroup(name, description);
        setCompleted(true);
      } catch (error: any) {
        setErrorStatusCode(error["status"]);
        setErrorTitle("Submit Failed");
        setErrorMessage("Processing failed");
        setErrorOpen(true);
      } finally {
        setSubmitDisabled(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (name?.length) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [name]);

  if (completed) {
    return <Redirect to="/campaigns" />;
  }

  return (
    <div>
      <Toolbar disableGutters>
        <IconButton disabled>
          <PrimaryAvatar>
            <Icon>campaign</Icon>
          </PrimaryAvatar>
        </IconButton>
        <Typography variant="h6">New Campaign</Typography>
      </Toolbar>
      <form autoComplete="off">
        <TextField
          label="Name"
          variant="outlined"
          disabled={loading}
          fullWidth
          onChange={onNameChange}
        />
        <TextField
          label="Description"
          variant="outlined"
          className={styles.field}
          disabled={loading}
          fullWidth
          onChange={onDescriptionChange}
        />
      </form>
      <Toolbar disableGutters>
        <Grid container justifyContent="flex-end">
          <CancelButton buttonLink="/campaigns" />
          <SubmitButton onClick={onSubmitClick} disabled={submitDisabled} />
        </Grid>
      </Toolbar>
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
    </div>
  );
}

export default NewCampaign;
