import React from "react";
import { Button } from "@material-ui/core";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

function SubmitButton({ disabled = false, onClick }: Props) {
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={disabled}
      onClick={onClick}
    >
      Submit
    </Button>
  );
}

export default SubmitButton;
