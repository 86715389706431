import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import { DigitalDocumentPermission } from "./DigitalDocumentPermission";

export class AppRoleAssignment extends jsonapi(Model) {
  static type = "app-role-assignments";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public principalId!: string;

  @Attribute()
  public appRoleId!: string;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;
}
