import React from "react";
import { Avatar, Icon } from "@material-ui/core";
import CreativeWorkStatus from "../store/models/CreativeWorkStatus";
import PrimaryAvatar from "./PrimaryAvatar";

interface Props {
  creativeWorkStatus: CreativeWorkStatus;
  icon: string;
}

function StatusAvatar(props: Props) {
  const icon = <Icon>{props.icon}</Icon>;
  let avatar = <PrimaryAvatar>{icon}</PrimaryAvatar>;
  if (props.creativeWorkStatus === CreativeWorkStatus.ARCHIVED) {
    avatar = <Avatar>{icon}</Avatar>;
  }
  return avatar;
}

export default StatusAvatar;
