import msalConfiguration from "../security/msalConfiguration";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthProviderCallback,
  Client,
} from "@microsoft/microsoft-graph-client";
import {
  AppRoleAssignment,
  DirectoryRole,
  Group,
  Invitation,
  User,
} from "@microsoft/microsoft-graph-types";
import { v4 as uuid } from "uuid";
import ApiPermission from "../security/ApiPermission";
import DirectoryRoleId from "../security/DirectoryRoleId";

const application = new PublicClientApplication(msalConfiguration);
const scopes = [ApiPermission.USER_READ];

const groupProperties = [
  "id",
  "displayName",
  "description",
  "createdDateTime",
  "visibility",
  "securityEnabled",
  "mailNickname",
];
const userProperties = [
  "id",
  "displayName",
  "mail",
  "otherMails",
  "userPrincipalName",
  "externalUserState",
  "userType",
  "createdDateTime",
  "accountEnabled",
];

function getGraphClient(accessToken: string) {
  const client = Client.init({
    authProvider: (authProviderCallback: AuthProviderCallback) => {
      authProviderCallback(undefined, accessToken);
    },
  });
  return client;
}

function getAccount() {
  const accounts = application.getAllAccounts();
  const account = accounts.pop();
  if (account) {
    return account;
  } else {
    throw new Error("Account not found");
  }
}

async function getAccessToken(requestedScopes: string[] = scopes) {
  const account = getAccount();
  const request = {
    account: account,
    scopes: requestedScopes,
  };
  const authenticationResult = await application.acquireTokenSilent(request);
  return authenticationResult.accessToken;
}

export async function addAppRoleAssignment(
  principalId: string,
  resourceId: string,
  appRoleId: string
) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(
    `/servicePrincipals/${resourceId}/appRoleAssignedTo`
  );
  const appRoleAssignment: AppRoleAssignment = {
    principalId: principalId,
    resourceId: resourceId,
    appRoleId: appRoleId,
  };
  const response = await request.post(appRoleAssignment);
  return response as AppRoleAssignment;
}

export async function removeAppRoleAssignment(
  resourceId: string,
  appRoleAssignmentId: string
) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(
    `/servicePrincipals/${resourceId}/appRoleAssignedTo/${appRoleAssignmentId}`
  );
  await request.delete();
}

export async function createGroup(
  displayName: string,
  description: string | undefined
) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api("/groups");
  const group: Group = {
    displayName: displayName,
    description: description,
    securityEnabled: true,
    mailEnabled: false,
    mailNickname: uuid(),
    visibility: "Private",
  };
  const response = await request.post(group);
  return response as Group;
}

export async function getUserAppRoleAssignments(id: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(`/users/${id}/appRoleAssignments`);
  const response = await request.get();
  return response.value as AppRoleAssignment[];
}

export async function getUserMemberOfGroups(id: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api(`/users/${id}/memberOf/microsoft.graph.group`)
    .orderby("displayName")
    .select(groupProperties);
  const response = await request.get();
  return response.value as Group[];
}

export async function getUserMemberOfDirectoryRoles(id: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api(`/users/${id}/memberOf/microsoft.graph.directoryRole`)
    .select(groupProperties);
  const response = await request.get();
  return response.value as DirectoryRole[];
}

export async function addDirectoryRoleMember(
  memberId: string,
  directoryRoleId: DirectoryRoleId
) {
  const accessToken = await getAccessToken([
    ApiPermission.DIRECTORY_ACCESS_AS_USER_ALL,
  ]);
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(
    `/directoryRoles/${directoryRoleId}/members/$ref`
  );
  const entity = {
    "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${memberId}`,
  };
  await request.post(entity);
}

export async function getUsers(mail?: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api("/users").select(userProperties);
  if (mail) {
    request.filter(`mail eq '${mail}'`).header("ConsistencyLevel", "eventual");
  }
  const response = await request.get();
  return response.value as User[];
}

export async function getMemberOfGroups(search?: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api("/me/memberOf/microsoft.graph.group")
    .orderby("displayName")
    .select(groupProperties);
  if (search) {
    request
      .search(`"displayName:${search}"`)
      .header("ConsistencyLevel", "eventual");
  }
  const response = await request.get();
  return response.value as Group[];
}

export async function getMemberOfDirectoryRoles(search?: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api("/me/memberOf/microsoft.graph.directoryRole")
    .select(groupProperties);
  if (search) {
    request
      .search(`"displayName:${search}"`)
      .header("ConsistencyLevel", "eventual");
  }
  const response = await request.get();
  return response.value as DirectoryRole[];
}

export async function getEnabledGroups() {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api("/groups/microsoft.graph.group")
    .orderby("displayName")
    .select(groupProperties)
    .header("ConsistencyLevel", "eventual");
  const response = await request.get();
  const groups = response.value as Group[];
  return groups.filter((group) => !group.mailNickname?.startsWith("DISABLED"));
}

export async function getGroups(search?: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api("/groups/microsoft.graph.group")
    .orderby("displayName")
    .select(groupProperties);
  if (search) {
    request
      .search(`"displayName:${search}"`)
      .header("ConsistencyLevel", "eventual");
  }
  const response = await request.get();
  return response.value as Group[];
}

export async function getGroup(id: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(`/groups/${id}`).select(groupProperties);
  const response = await request.get();
  return response as Group;
}

export async function updateGroup(group: Group) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(`/groups/${group.id}`);
  await request.patch(group);
}

export async function updateUser(user: User) {
  const accessToken = await getAccessToken([
    ApiPermission.DIRECTORY_ACCESS_AS_USER_ALL,
  ]);
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(`/users/${user.id}`);
  const patchUser: User = {
    accountEnabled: user.accountEnabled,
  };
  await request.patch(patchUser);
}

export async function getGroupMembers(id: string, search?: string) {
  const accessToken = await getAccessToken([
    ApiPermission.DIRECTORY_ACCESS_AS_USER_ALL,
  ]);
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api(`/groups/${id}/members`)
    .select(userProperties);
  if (search) {
    request
      .search(`"displayName:${search}"`)
      .header("ConsistencyLevel", "eventual");
  }
  const response = await request.get();
  const members = response.value as User[];
  members.sort((firstUser: User, secondUser: User) => {
    if (firstUser.displayName) {
      const secondUserDisplayName = secondUser.displayName
        ? secondUser.displayName
        : firstUser.displayName;
      return firstUser.displayName.localeCompare(secondUserDisplayName);
    } else {
      return 0;
    }
  });
  return members;
}

export async function getGroupMembersCount(id: string) {
  const accessToken = await getAccessToken([
    ApiPermission.DIRECTORY_ACCESS_AS_USER_ALL,
  ]);
  const graphClient = getGraphClient(accessToken);
  const request = graphClient
    .api(`/groups/${id}/members/$count`)
    .header("ConsistencyLevel", "eventual");
  const response = await request.get();
  return response as number;
}

export async function getUser(id: string) {
  const accessToken = await getAccessToken();
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(`/users/${id}`).select(userProperties);
  const response = await request.get();
  return response as User;
}

export async function addGroupMember(groupId: string, memberId: string) {
  const accessToken = await getAccessToken([
    ApiPermission.DIRECTORY_ACCESS_AS_USER_ALL,
  ]);
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(`/groups/${groupId}/members/$ref`);
  const entity = {
    "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${memberId}`,
  };
  await request.post(entity);
}

export async function removeGroupMember(groupId: string, memberId: string) {
  const accessToken = await getAccessToken([
    ApiPermission.DIRECTORY_ACCESS_AS_USER_ALL,
  ]);
  const graphClient = getGraphClient(accessToken);
  const request = graphClient.api(
    `/groups/${groupId}/members/${memberId}/$ref`
  );
  await request.delete();
}

export async function createInvitation(
  emailAddress: string,
  displayName: string | undefined,
  sendInvitation: boolean
) {
  const accessToken = await getAccessToken([ApiPermission.USER_INVITE_ALL]);
  const graphClient = getGraphClient(accessToken);
  const appStoreUrl = "https://apps.apple.com/us/app/trireme/id1670269647";
  const playStoreUrl =
    "https://play.google.com/store/apps/details?id=app.trireme";
  const invitation: Invitation = {
    invitedUserEmailAddress: emailAddress,
    invitedUserDisplayName: displayName,
    inviteRedirectUrl: "https://trireme.app",
    sendInvitationMessage: sendInvitation,
    invitedUserMessageInfo: {
      customizedMessageBody: `Welcome to Trireme! Download Trireme from the Apple App Store at ${appStoreUrl} or from the Google Play Store at ${playStoreUrl}`,
    },
  };
  const response = await graphClient.api("/invitations").post(invitation);
  return response as Invitation;
}
