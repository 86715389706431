import React, { useCallback, useEffect, useState, ChangeEvent } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from "@material-ui/core";
import TimeAgo from "react-timeago";
import useCollections from "../../store/useCollections";
import withAuthorization from "../../security/withAuthorization";
import { List } from "../../store/models/List";
import styles from "./SurveyListsDialog.module.css";
import LoadingProgress from "../../components/LoadingProgress";
import { ListSurveyReference } from "../../store/models/ListSurveyReference";
import { DigitalDocumentPermission } from "../../store/models/DigitalDocumentPermission";
import GranteeType from "../../store/models/GranteeType";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";

interface Props {
  open: boolean;
  onClose: () => void;
  surveyId: string;
  title: string;
  permissions: DigitalDocumentPermission[];
  creativeWorkStatus: CreativeWorkStatus;
}

function SurveyListsDialog(props: Props) {
  const collections = useCollections();
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState<List[]>([]);
  const [listSurveyReferences, setListSurveyReferences] = useState<
    ListSurveyReference[]
  >([]);
  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);
  const [showAssigned, setShowAssigned] = useState(false);

  const onListClick = (listId: string) => {
    if (
      isNotReferenced(listId) &&
      props.creativeWorkStatus !== CreativeWorkStatus.ARCHIVED
    ) {
      const checked = !selectedListIds.includes(listId);
      setListId(checked, listId);
    }
  };

  const onSubmitClick = async () => {
    try {
      const references = selectedListIds.map((listId) => {
        return new ListSurveyReference({
          listId: listId,
          surveyId: props.surveyId,
          permissions: props.permissions,
        });
      });

      const newReferences = references.filter((reference) => {
        const foundReference = listSurveyReferences.find(
          (listSurveyReference) => {
            return listSurveyReference.listId === reference.listId;
          }
        );
        return foundReference === undefined;
      });

      for (let i = 0; i < newReferences.length; i++) {
        const reference = newReferences[i];
        collections.add(reference);
        await reference.save();
      }

      props.onClose();
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorMessage("Processing Failed");
    }
  };

  const onShowAssignedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowAssigned(event.target.checked);
  };

  const setListId = (checked: boolean, listId: string) => {
    let listIds = [...selectedListIds];
    if (checked) {
      listIds.push(listId);
    } else {
      listIds = selectedListIds.filter((id) => id !== listId);
    }
    setSelectedListIds(listIds);
  };

  const isChecked = (list: List) => {
    return selectedListIds.includes(list.id);
  };

  const isNotReferenced = (listId: string) => {
    const found = listSurveyReferences.find(
      (reference) => reference.listId === listId
    );
    return found === undefined;
  };

  const isDisabled = (list: List) => {
    const found = listSurveyReferences.find(
      (reference) => reference.listId === list.id
    );
    return found !== undefined;
  };

  const getModels = useCallback(async () => {
    setSelectedListIds([]);
    setErrorStatusCode(0);
    setErrorMessage(undefined);
    setLoading(true);

    try {
      const referencesOptions = {
        queryParams: {
          filter: {
            surveyId: props.surveyId,
          },
          custom: [
            {
              key: "page[limit]",
              value: "1000",
            },
          ],
        },
      };
      const referencesResponse = await collections.getMany(
        ListSurveyReference,
        referencesOptions
      );
      if (Array.isArray(referencesResponse.data)) {
        const referenceListIds = new Set<string>();
        referencesResponse.data.forEach((reference) =>
          referenceListIds.add(reference.listId)
        );
        setSelectedListIds(Array.from(referenceListIds));
        setListSurveyReferences(referencesResponse.data);
      }

      const groupPermission = props.permissions.find(
        (permission) => permission.grantee.granteeType === GranteeType.GROUP
      );
      const filter: Record<string, string> = {};
      if (groupPermission) {
        filter["permissions.grantee.id"] = groupPermission.grantee.id;
      }

      const options = {
        queryParams: {
          sort: "-dateCreated",
          filter: filter,
        },
      };

      const response = await collections.getMany(List, options);
      if (Array.isArray(response.data)) {
        setLists(response.data);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorMessage("Loading Failed");
    } finally {
      setLoading(false);
    }
  }, [collections, props.surveyId, props.permissions]);

  useEffect(() => {
    if (props.open) {
      withAuthorization(getModels, () => {
        setErrorMessage("Unable to get Access Token");
      });
    }
  }, [props.open, getModels, collections]);

  const filteredLists = showAssigned
    ? lists
    : lists.filter((list) => isNotReferenced(list.id));

  return (
    <Dialog onClose={props.onClose} open={props.open} fullWidth>
      <DialogTitle>
        Assign Survey to Lists
        <Typography color="textSecondary">{props.title}</Typography>
        <FormControlLabel
          control={
            <Switch
              size="small"
              color="primary"
              onChange={onShowAssignedChange}
            />
          }
          label="Show Assigned"
          labelPlacement="start"
          className={styles.switchAssigned}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          {loading ? (
            <LoadingProgress loading={loading} />
          ) : (
            filteredLists.length === 0 && <ListItem>No Lists Found</ListItem>
          )}
          {filteredLists.map((list) => (
            <ListItem
              key={list.id}
              dense
              button
              onClick={() => onListClick(list.id)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={isChecked(list)}
                  disabled={isDisabled(list)}
                />
              </ListItemIcon>
              <ListItemText
                primary={list.name}
                secondary={
                  <div className={styles.secondaryRow}>
                    <Icon fontSize="small">person</Icon>
                    <span className={styles.secondaryLabel}>
                      {list.totalVoterCount}
                    </span>
                    <Icon fontSize="small">home</Icon>
                    <span className={styles.secondaryLabel}>
                      {list.totalHouseholdCount}
                    </span>
                    <Icon fontSize="small">schedule</Icon>
                    <span className={styles.secondaryLabel}>
                      <TimeAgo date={list.dateModified} />
                    </span>
                  </div>
                }
              />
            </ListItem>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {errorMessage && (
          <Typography color="secondary">
            {errorStatusCode
              ? `HTTP ${errorStatusCode}: ${errorMessage}`
              : errorMessage}
          </Typography>
        )}
        <Button autoFocus onClick={props.onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={onSubmitClick}
          variant="contained"
          color="primary"
          disabled={
            lists.length === 0 ||
            props.creativeWorkStatus === CreativeWorkStatus.ARCHIVED
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SurveyListsDialog;
