import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import CreativeWorkStatus from "./CreativeWorkStatus";
import { DigitalDocumentPermission } from "./DigitalDocumentPermission";
import { VoterReference } from "./voter/VoterReference";

export class List extends jsonapi(Model) {
  static type = "lists";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public description!: string;

  @Attribute()
  public dateCreated!: Date;

  @Attribute()
  public dateModified!: Date;

  @Attribute()
  public creativeWorkStatus!: CreativeWorkStatus;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;

  @Attribute()
  public universeId!: string;

  @Attribute()
  public voterReferences!: Array<VoterReference>;

  @Attribute()
  public totalVoterCount!: number;

  @Attribute()
  public totalHouseholdCount!: number;
}
