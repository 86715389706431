import AppRole from "./AppRole";
import useAccountInfo from "./useAccountInfo";

function useAppRoleGranted(appRole: AppRole) {
  const accountInfo = useAccountInfo();
  let appRoleGranted = false;

  if (accountInfo?.idTokenClaims) {
    const idTokenClaims = accountInfo.idTokenClaims as any;
    if (idTokenClaims.roles) {
      const roles = idTokenClaims.roles as string[];
      appRoleGranted = roles.includes(appRole);
    }
  }

  return appRoleGranted;
}

export default useAppRoleGranted;
