import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import CreativeWorkStatus from "./CreativeWorkStatus";
import { DigitalDocumentPermission } from "./DigitalDocumentPermission";
import { Question } from "./Question";
import { Script } from "./Script";

export class Survey extends jsonapi(Model) {
  static type = "surveys";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public version!: number;

  @Attribute()
  public name!: string;

  @Attribute()
  public description!: string;

  @Attribute()
  public dateCreated!: Date;

  @Attribute()
  public dateModified!: Date;

  @Attribute()
  public creativeWorkStatus!: CreativeWorkStatus;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;

  @Attribute()
  public questions!: Array<Question>;

  @Attribute()
  public script!: Script;
}
