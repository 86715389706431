import AccountGroup from "./AccountGroup";
import ApiPermission from "./ApiPermission";
import useAccountInfo from "./useAccountInfo";

function useApiPermissionGranted(apiPermission: ApiPermission) {
  const accountInfo = useAccountInfo();
  let apiPermissionGranted = false;

  if (accountInfo?.idTokenClaims) {
    const idTokenClaims = accountInfo.idTokenClaims as any;
    if (idTokenClaims.groups) {
      const groups = idTokenClaims.groups as string[];
      if (ApiPermission.USER_INVITE_ALL === apiPermission) {
        apiPermissionGranted = groups.includes(AccountGroup.GUEST_INVITER);
      } else if (ApiPermission.GROUP_READ_WRITE_ALL === apiPermission) {
        apiPermissionGranted = groups.includes(
          AccountGroup.GROUPS_ADMINISTRATOR
        );
      } else if (ApiPermission.USER_READ_WRITE_ALL === apiPermission) {
        apiPermissionGranted =
          groups.includes(AccountGroup.GLOBAL_ADMINISTRATOR) ||
          groups.includes(AccountGroup.USER_ADMINISTRATOR);
      }
    }
  }

  return apiPermissionGranted;
}

export default useApiPermissionGranted;
