import React, { ChangeEvent } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import CreativeWorkStatus from "../store/models/CreativeWorkStatus";
import styles from "./StatusSwitch.module.css";

interface Props {
  creativeWorkStatus: CreativeWorkStatus;
  onChangeStatus: (creativeWorkStatus: CreativeWorkStatus) => void;
}

function StatusSwitch(props: Props) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let creativeWorkStatus = CreativeWorkStatus.ARCHIVED;
    if (event.target.checked) {
      creativeWorkStatus = CreativeWorkStatus.PUBLISHED;
    }
    props.onChangeStatus(creativeWorkStatus);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          color="primary"
          checked={props.creativeWorkStatus !== CreativeWorkStatus.ARCHIVED}
          onChange={onChange}
        />
      }
      label={
        props.creativeWorkStatus === CreativeWorkStatus.ARCHIVED
          ? CreativeWorkStatus.ARCHIVED
          : "ACTIVE"
      }
      labelPlacement="start"
      className={styles.switch}
    />
  );
}

export default StatusSwitch;
