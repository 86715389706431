import React from "react";
import { Avatar } from "@material-ui/core";
import styles from "./PrimaryAvatar.module.css";

interface Props {
  children?: any;
}

function PrimaryAvatar(props: Props) {
  return <Avatar className={styles.avatar}>{props.children}</Avatar>;
}

export default PrimaryAvatar;
