import React from "react";
import { Button, Icon } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import DigitalDocumentPermissionType from "../store/models/DigitalDocumentPermissionType";
import GranteeType from "../store/models/GranteeType";
import { DigitalDocumentPermission } from "../store/models/DigitalDocumentPermission";

interface Props {
  permissions: DigitalDocumentPermission[];
}

function GroupPermissionButton(props: Props) {
  return (
    <>
      {props.permissions
        .filter(
          (permission) =>
            permission.grantee.granteeType === GranteeType.GROUP &&
            permission.permissionType === DigitalDocumentPermissionType.READ
        )
        .map((permission) => (
          <Button
            key={permission.grantee.id}
            component={RouterLink}
            to={`/campaigns/${permission.grantee.id}`}
            startIcon={<Icon>campaign</Icon>}
          >
            {permission.grantee.name}
          </Button>
        ))}
    </>
  );
}

export default GroupPermissionButton;
