import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  Chip,
  Card,
  CardHeader,
  Icon,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Button,
} from "@material-ui/core";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import { List } from "../../store/models/List";
import { ReplyAction } from "../../store/models/ReplyAction";
import useCollections from "../../store/useCollections";
import withAuthorization from "../../security/withAuthorization";
import { Voter } from "../../store/models/voter/Voter";
import { saveCommaSeparatedValues } from "../../services/FileDownloadService";
import styles from "./ListCard.module.css";
import { getHaversineDistance } from "../../services/LocationService";
import ScheduleChip from "../../components/ScheduleChip";
import StatusAvatar from "../../components/StatusAvatar";

type MatchParams = {
  id: string;
};

const HEADER = [
  "Voter ID",
  "Gender",
  "Age",
  "Party",
  "Family Name",
  "Given Name",
  "Address",
  "Status",
  "Surveyed",
  "Distance",
  "User",
];

const TIMESTAMP_SEPARATOR = ".";
const DISTANCE_FRACTION_DIGITS = 3;
const STREET_ADDRESS_PATTERN = new RegExp("^(\\d+)\\s+(.+)");
const UNKNOWN_STREET_NUMBER = 0;

function ListView({ match }: RouteComponentProps<MatchParams>) {
  const collections = useCollections();
  const [list, setList] = useState<List>();
  const [records, setRecords] = useState<Array<any[]>>([]);
  const [replyActions, setReplyActions] = useState<Map<string, ReplyAction>>(
    new Map()
  );
  const [loading, setLoading] = useState(true);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const getHouseholdReplyActions = () => {
    const households = new Set();
    replyActions.forEach((replyAction) =>
      households.add(replyAction.participant.householdId)
    );
    return households.size;
  };

  const getAge = (birthDate?: Date) => {
    let age = undefined;

    if (birthDate) {
      const now = new Date();
      const born = new Date(birthDate);
      age = now.getFullYear() - born.getFullYear();
    }

    return age;
  };

  const getSurveyed = (endTime?: string) => {
    let surveyed = undefined;

    if (endTime) {
      surveyed = endTime.split(TIMESTAMP_SEPARATOR)[0];
    }

    return surveyed;
  };

  const getSurveyedDistance = (voter: Voter, replyAction?: ReplyAction) => {
    let distance = undefined;

    if (replyAction) {
      const geo = voter.person.homeLocation.geo;
      const replyActionGeo = replyAction.location.geo;
      const haversineDistance = getHaversineDistance(geo, replyActionGeo);
      distance = `${haversineDistance.toFixed(DISTANCE_FRACTION_DIGITS)} km`;
    }

    return distance;
  };

  const compareAddresses = useCallback(
    (firstAddress: string, secondAddress: string) => {
      const firstStreetAddress = getStreetAddress(firstAddress);
      const firstStreetName = firstStreetAddress[1].toString();

      const secondStreetAddress = getStreetAddress(secondAddress);
      const secondStreetName = secondStreetAddress[1].toString();
      if (firstStreetName === secondStreetName) {
        const firstNumber = firstStreetAddress[0];
        const secondNumber = secondStreetAddress[0];
        if (firstNumber === secondNumber) {
          return 0;
        } else if (firstNumber > secondNumber) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return firstStreetName.localeCompare(secondStreetName);
      }
    },
    []
  );

  const compareFields = useCallback(
    (firstRecord: Array<any>, secondRecord: Array<any>): number => {
      const sortField = 6;
      const firstField = firstRecord[sortField];
      const secondField = secondRecord[sortField];
      if (firstField === secondField) {
        return 0;
      }
      if (firstField === undefined) {
        return -1;
      }
      if (secondField === undefined) {
        return 1;
      }
      return compareAddresses(firstField, secondField);
    },
    [compareAddresses]
  );

  const getStreetAddress = (address: string) => {
    const results = STREET_ADDRESS_PATTERN.exec(address);
    return results
      ? [parseInt(results[1]), results[2]]
      : [UNKNOWN_STREET_NUMBER, address];
  };

  const getRecords = useCallback(
    (voters: Voter[], foundActions: Map<string, ReplyAction>): Array<any[]> => {
      const records = voters.map((voter) => {
        const record = new Array<any>();
        record.push(voter.registration.id);
        record.push(voter.person.gender);
        record.push(getAge(voter.person.birthDate));
        record.push(voter.registration.party);
        record.push(voter.person.familyName);
        record.push(voter.person.givenName);
        record.push(voter.person.homeLocation.address.id);

        const replyAction = foundActions.get(voter.id);
        record.push(replyAction?.actionStatus.name);
        record.push(getSurveyed(replyAction?.endTime));
        record.push(getSurveyedDistance(voter, replyAction));
        record.push(replyAction?.agent.name);
        return record;
      });

      return records.sort(compareFields);
    },
    [compareFields]
  );

  const getModels = useCallback(async () => {
    setLoading(true);
    const listId = match.params.id;

    try {
      const foundList = await collections.getOne(List, listId);
      setList(foundList.data as List);

      const queryParams = {
        filter: {
          listId: listId,
        },
        sort: "registration.id",
        custom: [
          {
            key: "page[limit]",
            value: "1000",
          },
        ],
      };
      const options = {
        queryParams: queryParams,
      };

      const votersResponse = await collections.getMany(Voter, options);

      const actionOptions = {
        queryParams: {
          filter: {
            "actionObject.id": listId,
          },
          custom: [
            {
              key: "page[limit]",
              value: "1000",
            },
          ],
        },
      };
      const replyActionsResponse = await collections.getMany(
        ReplyAction,
        actionOptions
      );

      if (Array.isArray(votersResponse.data)) {
        const foundActions = new Map<string, ReplyAction>();
        if (Array.isArray(replyActionsResponse.data)) {
          replyActionsResponse.data.forEach((replyAction) =>
            foundActions.set(replyAction.participant.id, replyAction)
          );
        }
        setReplyActions(foundActions);

        const processedRecords = getRecords(votersResponse.data, foundActions);
        setRecords(processedRecords);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Loading Failed");
      setErrorMessage("Processing Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }, [match.params.id, collections, getRecords]);

  const onDownloadClick = () => {
    saveCommaSeparatedValues(records, HEADER, list?.name);
  };

  useEffect(() => {
    withAuthorization(getModels, () => {
      setErrorTitle("Authorization Failed");
      setErrorMessage("Unable to get Access Token");
      setErrorOpen(true);
    });
  }, [getModels, collections]);

  let card = <Card></Card>;
  if (list) {
    card = (
      <>
        <Card className={styles.card}>
          <CardHeader
            avatar={
              <StatusAvatar
                creativeWorkStatus={list.creativeWorkStatus}
                icon="assignment"
              />
            }
            title={list.name}
            subheader={
              <div>
                <Chip
                  label={`${replyActions.size} of ${list.totalVoterCount}`}
                  className={styles.chip}
                  size="small"
                  variant="outlined"
                  icon={<Icon fontSize="small">person</Icon>}
                />
                <Chip
                  label={`${getHouseholdReplyActions()} of ${
                    list.totalHouseholdCount
                  }`}
                  className={styles.chip}
                  size="small"
                  variant="outlined"
                  icon={<Icon fontSize="small">home</Icon>}
                />
                <ScheduleChip label="Created" dateTime={list.dateModified} />
              </div>
            }
            action={
              <Button
                variant="contained"
                color="primary"
                className={styles.actionButton}
                disabled={records.length === 0}
                onClick={onDownloadClick}
              >
                Download
              </Button>
            }
          />
        </Card>
        <TableContainer component={Paper} className={styles.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Voter ID</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Party</TableCell>
                <TableCell>Family Name</TableCell>
                <TableCell>Given Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Surveyed</TableCell>
                <TableCell>Distance</TableCell>
                <TableCell>User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((record) => (
                <TableRow key={record[0]}>
                  {record.map((field) => (
                    <TableCell>{field}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <div>
      {card}
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
    </div>
  );
}

export default ListView;
