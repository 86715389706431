import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Icon,
} from "@material-ui/core";
import { Survey } from "../../store/models/Survey";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";
import styles from "./SurveyCard.module.css";
import SurveyListsDialog from "./SurveyListsDialog";
import GroupPermissionButton from "../../components/GroupPermissionButton";
import ScheduleChip from "../../components/ScheduleChip";
import StatusSwitch from "../../components/StatusSwitch";
import StatusAvatar from "../../components/StatusAvatar";
import AppRoleGranted from "../../security/AppRoleGranted";
import AppRole from "../../security/AppRole";

type Props = {
  survey: Survey;
  onChange: (survey: Survey) => void;
};

function SurveyCard({ survey, onChange }: Props) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [creativeWorkStatus, setCreativeWorkStatus] = useState(
    survey.creativeWorkStatus
  );

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  const onListsClick = () => {
    setDialogOpen(true);
  };

  const onChangeStatus = (status: CreativeWorkStatus) => {
    setCreativeWorkStatus(status);
    survey.creativeWorkStatus = status;
    onChange(survey);
  };

  return (
    <Card className={styles.listCard}>
      <CardHeader
        title={survey.name}
        avatar={
          <StatusAvatar
            creativeWorkStatus={creativeWorkStatus}
            icon="question_answer"
          />
        }
        subheader={
          <div>
            <Chip
              label={survey.questions.length}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">chat_bubble</Icon>}
            />
            <ScheduleChip dateTime={survey.dateModified} />
          </div>
        }
      />
      <CardActions>
        <div className={styles.actionsContainer}>
          <Button
            variant="contained"
            startIcon={<Icon>assignment</Icon>}
            onClick={onListsClick}
          >
            Lists
          </Button>
          <GroupPermissionButton permissions={survey.permissions} />
        </div>
        <AppRoleGranted appRole={AppRole.SURVEY_READ_WRITE}>
          <StatusSwitch
            creativeWorkStatus={creativeWorkStatus}
            onChangeStatus={onChangeStatus}
          />
        </AppRoleGranted>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/surveys/${survey.id}`}
        >
          View
        </Button>
      </CardActions>
      <SurveyListsDialog
        open={dialogOpen}
        onClose={onDialogClose}
        surveyId={survey.id}
        title={survey.name}
        permissions={survey.permissions}
        creativeWorkStatus={creativeWorkStatus}
      />
    </Card>
  );
}

export default SurveyCard;
