import React, { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  completed: boolean;
  title?: string;
  message?: ReactNode;
}

function ProcessingDialog(props: Props) {
  const onDialogClose = () => {
    if (props.completed) {
      props.onClose();
    }
  };

  return (
    <Dialog
      onClose={onDialogClose}
      open={props.open}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography paragraph>{props.message}</Typography>
          <LinearProgress
            variant={props.completed ? "determinate" : "indeterminate"}
            value={props.completed ? 100 : 0}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDialogClose} disabled={!props.completed}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProcessingDialog;
