import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import LoadingProgress from "../../components/LoadingProgress";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import {
  DirectoryRole,
  Group,
  User as GraphUser,
} from "@microsoft/microsoft-graph-types";
import {
  getUserAppRoleAssignments,
  getUser,
  getUserMemberOfGroups,
  getUserMemberOfDirectoryRoles,
  getEnabledGroups,
  getMemberOfGroups,
  updateUser,
  addDirectoryRoleMember,
  addGroupMember,
  removeGroupMember,
} from "../../services/GraphService";
import { AppRoles, getAppRoleLabel } from "../../security/AppRoles";
import AccountStatus from "../../store/models/AccountStatus";
import AppRoleGranted from "../../security/AppRoleGranted";
import AppRole from "../../security/AppRole";
import CancelButton from "../../components/buttons/CancelButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import useAccountInfo from "../../security/useAccountInfo";
import useApiPermissionGranted from "../../security/useApiPermissionGranted";
import ApiPermission from "../../security/ApiPermission";
import useAppRoleGranted from "../../security/useAppRoleGranted";
import { AppRoleAssignment } from "../../store/models/AppRoleAssignment";
import { Grantee } from "../../store/models/Grantee";
import GranteeType from "../../store/models/GranteeType";
import DigitalDocumentPermissionType from "../../store/models/DigitalDocumentPermissionType";
import { DigitalDocumentPermission } from "../../store/models/DigitalDocumentPermission";
import { IJsonapiModel } from "@datx/jsonapi";
import { setMeta } from "@datx/utils";
import withAuthorization from "../../security/withAuthorization";
import { GroupMember } from "../../store/models/GroupMember";
import DirectoryRoleId from "../../security/DirectoryRoleId";
import DirectoryRoleLabel from "../../security/DirectoryRoleLabel";

type MatchParams = {
  id: string;
};

const MODEL_PERSISTED_FIELD = "networkPersisted";

function User({ match }: RouteComponentProps<MatchParams>) {
  const [loading, setLoading] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [user, setUser] = useState<GraphUser>();
  const [userAccountEnabled, setUserAccountEnabled] = useState(false);
  const [guestInviterEnabled, setGuestInviterEnabled] = useState(false);
  const [directoryRoles, setDirectoryRoles] = useState<DirectoryRole[]>([]);
  const [appRoleAssignments, setAppRoleAssignments] = useState<
    AppRoleAssignment[]
  >([]);
  const [selectedAppRoleIds, setSelectedAppRoleIds] = useState<string[]>([]);
  const [memberGroups, setMemberGroups] = useState<Group[]>([]);
  const [selectedMemberGroupIds, setSelectedMemberGroupIds] = useState<
    string[]
  >([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [completed, setCompleted] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const accountInfo = useAccountInfo();

  const appRoleAssignmentReadWriteGranted = useAppRoleGranted(
    AppRole.APP_ROLE_ASSIGNMENT_READ_WRITE
  );
  const groupPermissionGranted = useApiPermissionGranted(
    ApiPermission.GROUP_READ_WRITE_ALL
  );
  const userPermissionGranted = useApiPermissionGranted(
    ApiPermission.USER_READ_WRITE_ALL
  );

  const hasPermissions =
    appRoleAssignmentReadWriteGranted ||
    groupPermissionGranted ||
    userPermissionGranted;

  useEffect(() => {
    getUser(match.params.id)
      .then((foundUser) => setUser(foundUser))
      .catch(() => setUser(undefined))
      .finally(() => setLoading(false));
  }, [match.params.id]);

  useEffect(() => {
    if (user?.id) {
      setUserAccountEnabled(user?.accountEnabled === true);
      getUserAppRoleAssignments(user.id)
        .then((userAppRoleAssignments) => {
          const currentAppRoleAssignments = userAppRoleAssignments.map(
            (assignment) =>
              new AppRoleAssignment({
                id: assignment.id,
                principalId: assignment.principalId,
                appRoleId: assignment.appRoleId,
              })
          );
          setAppRoleAssignments(currentAppRoleAssignments);
        })
        .catch(() => setAppRoleAssignments([]));
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      getUserMemberOfDirectoryRoles(user.id)
        .then((userDirectoryRoles) => {
          const filteredDirectoryRoles = userDirectoryRoles.filter(
            (directoryRole) => directoryRole.displayName
          );
          setDirectoryRoles(filteredDirectoryRoles);
        })
        .catch(() => setDirectoryRoles([]));
    }
  }, [user]);

  const findGuestInviterDirectoryRole = useCallback(() => {
    return directoryRoles.find(
      (directoryRole) => DirectoryRoleId.GUEST_INVITER === directoryRole.id
    );
  }, [directoryRoles]);

  useEffect(() => {
    const foundGuestInviterRole = findGuestInviterDirectoryRole();
    const enabled = foundGuestInviterRole !== undefined;
    setGuestInviterEnabled(enabled);
  }, [directoryRoles, findGuestInviterDirectoryRole]);

  useEffect(() => {
    if (user?.id) {
      getUserMemberOfGroups(user.id)
        .then((userGroups) => {
          if (groupPermissionGranted) {
            setMemberGroups(userGroups);
          } else {
            getMemberOfGroups()
              .then((currentMemberGroups) => {
                // Filter member groups based on authenticated user member groups
                const currentMemberGroupIds = currentMemberGroups.map(
                  (memberGroup) => memberGroup.id
                );
                const matchedUserGroups = userGroups.filter((userGroup) =>
                  currentMemberGroupIds.includes(userGroup.id)
                );
                setMemberGroups(matchedUserGroups);
              })
              .catch(() => setMemberGroups([]));
          }
        })
        .catch(() => setMemberGroups([]));

      if (groupPermissionGranted) {
        getEnabledGroups()
          .then((enabledGroups) => setGroups(enabledGroups))
          .catch(() => setGroups([]));
      }
    }
  }, [user, groupPermissionGranted]);

  useEffect(() => {
    const memberGroupIds = memberGroups.map((memberGroup) => memberGroup.id!);
    setSelectedMemberGroupIds(memberGroupIds);
  }, [memberGroups]);

  useEffect(() => {
    const appRoleIds = appRoleAssignments.map(
      (appRoleAssignment) => appRoleAssignment.appRoleId!
    );
    setSelectedAppRoleIds(appRoleIds);
  }, [appRoleAssignments]);

  const isAppRoleChecked = (appRole: AppRole) => {
    let checked = false;
    const appRoleId = AppRoles.get(appRole);
    if (appRoleId) {
      checked = selectedAppRoleIds.includes(appRoleId);
    }
    return checked;
  };

  const isAppRoleDisabled = (appRole: AppRole) => {
    let disabled = true;
    if (appRoleAssignmentReadWriteGranted) {
      disabled = false;
    }
    return disabled;
  };

  const isGroupDisabled = (groupId: string) => {
    let disabled = true;
    if (groupPermissionGranted) {
      disabled = false;
    }
    return disabled;
  };

  const isGuestInviterEnabledDisabled = () => {
    const foundGuestInviter = findGuestInviterDirectoryRole();
    return foundGuestInviter !== undefined || !userPermissionGranted;
  };

  const onAppRoleChange = (
    event: ChangeEvent<HTMLInputElement>,
    appRole: AppRole
  ) => {
    const appRoleId = AppRoles.get(appRole);
    if (appRoleId) {
      if (event.target.checked) {
        const updatedAppRoleIds = [...selectedAppRoleIds, appRoleId];
        setSelectedAppRoleIds(updatedAppRoleIds);
      } else {
        const updatedAppRoleIds = selectedAppRoleIds.filter(
          (currentAppRoleId) => currentAppRoleId !== appRoleId
        );
        setSelectedAppRoleIds(updatedAppRoleIds);
      }
    }
  };

  const onGroupChange = (
    event: ChangeEvent<HTMLInputElement>,
    group: Group
  ) => {
    const groupId = group.id!;
    if (event.target.checked) {
      const updatedGroupIds = [...selectedMemberGroupIds, groupId];
      setSelectedMemberGroupIds(updatedGroupIds);
    } else {
      const updatedGroupIds = selectedMemberGroupIds.filter(
        (memberGroupId) => memberGroupId !== groupId
      );
      setSelectedMemberGroupIds(updatedGroupIds);
    }
  };

  const onAccountEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserAccountEnabled(!userAccountEnabled);
  };

  const onGuestInviterEnabledChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setGuestInviterEnabled(!guestInviterEnabled);
  };

  const getUserPermission = useCallback(() => {
    const userGrantee = new Grantee();
    userGrantee.id = accountInfo!.localAccountId;
    userGrantee.name = accountInfo!.username;
    userGrantee.granteeType = GranteeType.USER;

    const userPermission = new DigitalDocumentPermission();
    userPermission.grantee = userGrantee;
    userPermission.permissionType = DigitalDocumentPermissionType.WRITE;

    return userPermission;
  }, [accountInfo]);

  const getGroupPermission = (groupId: string) => {
    const groupGrantee = new Grantee();
    groupGrantee.id = groupId;
    groupGrantee.name = groupId;
    groupGrantee.granteeType = GranteeType.GROUP;

    const groupPermission = new DigitalDocumentPermission();
    groupPermission.grantee = groupGrantee;
    groupPermission.permissionType = DigitalDocumentPermissionType.WRITE;

    return groupPermission;
  };

  const createGroupMember = (
    directoryObjectId: string,
    groupId: string
  ): Promise<IJsonapiModel> => {
    const groupPermission = getGroupPermission(groupId);
    const groupMember = new GroupMember({
      directoryObjectId: directoryObjectId,
      permissions: [groupPermission],
    });
    return groupMember.save();
  };

  const destroyGroupMember = (
    directoryObjectId: string,
    groupId: string
  ): Promise<void> => {
    const groupPermission = getGroupPermission(groupId);
    const groupMember = new GroupMember({
      id: `${groupId}-${directoryObjectId}`,
      directoryObjectId: directoryObjectId,
      permissions: [groupPermission],
    });
    setMeta(groupMember, MODEL_PERSISTED_FIELD, true);
    return groupMember.destroy();
  };

  const createAppRoleAssignment = (
    principalId: string,
    appRoleId: string
  ): Promise<IJsonapiModel> => {
    const userPermission = getUserPermission();
    const appRoleAssignment = new AppRoleAssignment({
      principalId: principalId,
      appRoleId: appRoleId,
      permissions: [userPermission],
    });
    return appRoleAssignment.save();
  };

  const removeAppRoleAssignment = (appRoleId: string) => {
    const appRoleAssignment = appRoleAssignments.find(
      (assignment) => assignment.appRoleId === appRoleId
    );
    let promise = Promise.resolve();
    if (appRoleAssignment) {
      setMeta(appRoleAssignment, MODEL_PERSISTED_FIELD, true);
      promise = appRoleAssignment.destroy();
    }
    return promise;
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const setTokenFailed = () => {
    setErrorTitle("Authorization Failed");
    setErrorMessage("Unable to get Access Token");
    setErrorOpen(true);
    setSubmitDisabled(false);
    setLoading(false);
  };

  const submitChanges = async () => {
    setSubmitDisabled(true);
    try {
      const principalId = user!.id!;

      const addAppRoleIds = selectedAppRoleIds.filter((appRoleId) => {
        const foundAppRoleAssignment = appRoleAssignments.find(
          (appRoleAssignment) => appRoleAssignment.appRoleId === appRoleId
        );
        return foundAppRoleAssignment === undefined;
      });
      const addAppRolePromises = addAppRoleIds.map((appRoleId) => {
        return createAppRoleAssignment(principalId, appRoleId);
      });
      await Promise.all(addAppRolePromises);

      const removeAppRoleIds = appRoleAssignments
        .filter((appRoleAssignment) => {
          const appRoleId = appRoleAssignment.appRoleId;
          return !selectedAppRoleIds.includes(appRoleId);
        })
        .map((appRoleAssignment) => appRoleAssignment.appRoleId);
      const removeAppRolePromises = removeAppRoleIds.map((appRoleId) => {
        return removeAppRoleAssignment(appRoleId);
      });
      await Promise.all(removeAppRolePromises);

      const addMemberGroupIds = selectedMemberGroupIds.filter((groupId) => {
        const foundMemberGroup = memberGroups.find(
          (memberGroup) => memberGroup.id === groupId
        );
        return foundMemberGroup === undefined;
      });
      const addMemberPromises = addMemberGroupIds.map((groupId) => {
        return groupPermissionGranted
          ? addGroupMember(groupId, principalId)
          : createGroupMember(principalId, groupId);
      });
      await Promise.all(addMemberPromises);

      const removeMemberGroupIds = memberGroups
        .filter((memberGroup) => {
          const memberGroupId = memberGroup.id!;
          return !selectedMemberGroupIds.includes(memberGroupId);
        })
        .map((memberGroup) => memberGroup.id!);
      const removeMemberPromises = removeMemberGroupIds.map((groupId) => {
        return groupPermissionGranted
          ? removeGroupMember(groupId, principalId)
          : destroyGroupMember(principalId, groupId);
      });
      await Promise.all(removeMemberPromises);

      if (userAccountEnabled !== user?.accountEnabled) {
        const updatedUser: GraphUser = {
          id: principalId,
          accountEnabled: userAccountEnabled,
        };
        await updateUser(updatedUser);
      }

      const guestInviterDirectoryRole = findGuestInviterDirectoryRole();
      if (guestInviterEnabled && guestInviterDirectoryRole === undefined) {
        await addDirectoryRoleMember(
          principalId,
          DirectoryRoleId.GUEST_INVITER
        );
      }

      setCompleted(true);
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Submit Failed");
      setErrorMessage(error["message"]);
      setErrorOpen(true);
    } finally {
      setSubmitDisabled(false);
      setLoading(false);
    }
  };

  const onSubmitClick = async () => {
    withAuthorization(submitChanges, setTokenFailed);
  };

  const isChecked = (groupId: string) => {
    return selectedMemberGroupIds.includes(groupId);
  };

  if (completed) {
    return <Redirect to="/users" />;
  }

  let container = <div>Account {match.params.id} not found</div>;
  if (loading) {
    container = (
      <Container>
        <LoadingProgress loading={loading} />
      </Container>
    );
  }

  if (user) {
    let groupsLabel = (
      <List dense disablePadding>
        <ListItem dense>No Campaigns Roles Found</ListItem>
      </List>
    );
    const listedGroups = groups?.length ? groups : memberGroups;
    if (listedGroups.length) {
      groupsLabel = (
        <List dense disablePadding>
          {listedGroups.map((group) => (
            <ListItem dense key={group.id}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={isChecked(group.id!)}
                  disabled={isGroupDisabled(group.id!)}
                  onChange={(event) => onGroupChange(event, group)}
                />
              </ListItemIcon>
              <ListItemText>{group.displayName}</ListItemText>
            </ListItem>
          ))}
        </List>
      );
    }

    const username = user.mail ? user.mail : user.otherMails?.[0];

    const avatar =
      user.externalUserState === AccountStatus.PENDING_ACCEPTANCE ? (
        <Avatar>
          <Icon>email</Icon>
        </Avatar>
      ) : (
        <PrimaryAvatar>
          <Icon>person</Icon>
        </PrimaryAvatar>
      );

    const appRoles = Array.from(AppRoles.keys()).map((appRole) => (
      <AppRoleGranted appRole={appRole} key={appRole}>
        <ListItem>
          <ListItemIcon>
            <Checkbox
              edge="start"
              color="primary"
              checked={isAppRoleChecked(appRole)}
              disabled={isAppRoleDisabled(appRole)}
              onChange={(event) => onAppRoleChange(event, appRole)}
            />
          </ListItemIcon>
          <ListItemText>{getAppRoleLabel(appRole)}</ListItemText>
        </ListItem>
      </AppRoleGranted>
    ));

    container = (
      <>
        <Card>
          <CardHeader
            avatar={avatar}
            title={user.displayName}
            titleTypographyProps={{
              variant: "h6",
            }}
            subheader={username}
          />
          <CardContent>
            <Grid container>
              <Grid item md={6}>
                <List dense disablePadding>
                  <ListItem>
                    <ListItemText
                      primary="Account"
                      secondary={
                        <List dense disablePadding>
                          <ListItem>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                color="primary"
                                checked={userAccountEnabled}
                                disabled={!userPermissionGranted}
                                onChange={onAccountEnabledChange}
                              />
                            </ListItemIcon>
                            <ListItemText>Enabled</ListItemText>
                          </ListItem>
                        </List>
                      }
                    />
                  </ListItem>
                </List>
                <List dense disablePadding>
                  <ListItem>
                    <ListItemText primary="Campaigns" secondary={groupsLabel} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6}>
                <List dense disablePadding>
                  <ListItem>
                    <ListItemText
                      primary="Application Roles"
                      secondary={
                        <List dense disablePadding>
                          {appRoles}
                        </List>
                      }
                    />
                  </ListItem>
                </List>
                <List dense disablePadding>
                  <ListItem>
                    <ListItemText
                      primary="Directory Roles"
                      secondary={
                        <List dense disablePadding>
                          <ListItem>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                color="primary"
                                checked
                                disabled
                              />
                            </ListItemIcon>
                            <ListItemText>{user.userType}</ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                color="primary"
                                checked={guestInviterEnabled}
                                disabled={isGuestInviterEnabledDisabled()}
                                onChange={onGuestInviterEnabledChange}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {DirectoryRoleLabel.GUEST_INVITER}
                            </ListItemText>
                          </ListItem>
                          {directoryRoles
                            .filter(
                              (directoryRole) =>
                                DirectoryRoleId.GUEST_INVITER !==
                                directoryRole.id
                            )
                            .map((directoryRole) => (
                              <ListItem key={directoryRole.id}>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    color="primary"
                                    checked
                                    disabled
                                  />
                                </ListItemIcon>
                                <ListItemText>
                                  {directoryRole.displayName}
                                </ListItemText>
                              </ListItem>
                            ))}
                        </List>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {hasPermissions && (
          <Toolbar disableGutters>
            <Grid container justifyContent="flex-end">
              <CancelButton buttonLink="/users" />
              <SubmitButton onClick={onSubmitClick} disabled={submitDisabled} />
            </Grid>
          </Toolbar>
        )}
        <ErrorDialog
          open={errorOpen}
          title={errorTitle}
          message={errorMessage}
          statusCode={errorStatusCode}
          onClose={onErrorDialogClose}
        />
      </>
    );
  }

  return container;
}

export default User;
