const CRLF = "\r\n";
const COMMA = ",";
const DATA_URI_PREFIX = "data:text/csv;charset=utf-8";

export function saveCommaSeparatedValues(
  records: any[],
  header?: string[],
  label?: string
) {
  const rows = records.map((record) => record.join(COMMA));
  if (header) {
    rows.unshift(header.join(COMMA));
  }

  const csv = encodeURIComponent(rows.join(CRLF));
  const uri = `${DATA_URI_PREFIX},${csv}`;
  const anchor = document.createElement("a");

  anchor.href = uri;
  anchor.download = `${label}.csv`;
  anchor.click();
}
