import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import useCollections from "../../store/useCollections";
import withAuthorization from "../../security/withAuthorization";
import styles from "./ListUsersDialog.module.css";
import { DigitalDocumentPermission } from "../../store/models/DigitalDocumentPermission";
import DigitalDocumentPermissionType from "../../store/models/DigitalDocumentPermissionType";
import GranteeType from "../../store/models/GranteeType";
import { User } from "@microsoft/microsoft-graph-types";
import { getGroupMembers } from "../../services/GraphService";
import { List } from "../../store/models/List";
import { Grantee } from "../../store/models/Grantee";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";
import SearchBar from "../../components/SearchBar";

interface Props {
  open: boolean;
  onClose: () => void;
  list: List;
  groupId: string;
  creativeWorkStatus: CreativeWorkStatus;
}

function ListUsersDialog(props: Props) {
  const collections = useCollections();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);
  const [search, setSearch] = useState<string>();

  const isNotReferenced = (userId: string) => {
    const found = props.list.permissions.find(
      (permission) =>
        permission.grantee.id === userId &&
        permission.permissionType === DigitalDocumentPermissionType.READ
    );
    return found === undefined;
  };

  const onSearchChangeHandler = (search: string) => {
    setSearch(search);
  };

  const onUserClick = (userId: string) => {
    if (
      isNotReferenced(userId) &&
      props.creativeWorkStatus !== CreativeWorkStatus.ARCHIVED
    ) {
      const checked = !selectedUserIds.includes(userId);
      setUserId(checked, userId);
    }
  };

  const setUserId = (checked: boolean, userId: string) => {
    let userIds = [...selectedUserIds];
    if (checked) {
      userIds.push(userId);
    } else {
      userIds = selectedUserIds.filter((id) => id !== userId);
    }
    setSelectedUserIds(userIds);
  };

  const onDialogClose = () => {
    setSearch(undefined);
    props.onClose();
  };

  const onSubmitClick = async () => {
    try {
      const selectedPermissions = selectedUserIds.map((userId) => {
        const foundUser = users.find((user) => user.id === userId)!;
        const username = getMail(foundUser)!;
        const userGrantee = new Grantee();
        userGrantee.id = userId;
        userGrantee.name = username;
        userGrantee.granteeType = GranteeType.USER;

        const userPermission = new DigitalDocumentPermission();
        userPermission.grantee = userGrantee;
        userPermission.permissionType = DigitalDocumentPermissionType.READ;

        return userPermission;
      });

      const newSelectedPermissions = selectedPermissions.filter(
        (permission) => {
          const foundPermission = props.list.permissions.find(
            (listPermission) => {
              return (
                listPermission.permissionType ===
                  DigitalDocumentPermissionType.READ &&
                listPermission.grantee.id === permission.grantee.id
              );
            }
          );
          return foundPermission === undefined;
        }
      );

      const permissions = props.list.permissions.concat(newSelectedPermissions);
      props.list.permissions = permissions;

      await props.list.save();

      onDialogClose();
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorMessage("Processing Failed");
    }
  };

  const isChecked = (user: User) => {
    return selectedUserIds.includes(user.id!);
  };

  const isDisabled = (user: User) => {
    const found = props.list.permissions.find(
      (permission) =>
        permission.grantee.id === user.id &&
        permission.permissionType === DigitalDocumentPermissionType.READ
    );
    return found !== undefined;
  };

  const getMail = (user: User) => {
    let otherMail = undefined;
    if (user.otherMails?.length) {
      const lastIndex = user.otherMails.length - 1;
      otherMail = user.otherMails[lastIndex];
    }
    return user.mail ? user.mail : otherMail;
  };

  const getModels = useCallback(async () => {
    setSelectedUserIds([]);
    setErrorStatusCode(0);
    setErrorMessage(undefined);
    setLoading(true);

    try {
      const groupMembers = await getGroupMembers(props.groupId, search);
      setUsers(groupMembers);

      const permissionUserIds = props.list.permissions
        .filter((permission) => {
          return (
            permission.permissionType === DigitalDocumentPermissionType.READ &&
            permission.grantee.granteeType === GranteeType.USER
          );
        })
        .map((permission) => permission.grantee.id);
      setSelectedUserIds(permissionUserIds);
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorMessage("Loading Failed");
    } finally {
      setLoading(false);
    }
  }, [props.list.permissions, props.groupId, search]);

  useEffect(() => {
    if (props.open) {
      withAuthorization(getModels, () => {
        setErrorMessage("Unable to get Access Token");
      });
    }
  }, [props.open, getModels, collections]);

  return (
    <Dialog onClose={props.onClose} open={props.open} fullWidth>
      <DialogTitle>
        Assign List to Users
        <Typography color="textSecondary">{props.list.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <SearchBar
            addButtonLink={"/users/new"}
            total={users.length}
            onSearchChangeHandler={onSearchChangeHandler}
          />
          {users.length === 0 && <ListItem>No Users Found</ListItem>}
          {users.map((user) => (
            <ListItem
              key={user.id}
              dense
              button
              onClick={() => onUserClick(user.id!)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={isChecked(user)}
                  disabled={isDisabled(user)}
                />
              </ListItemIcon>
              <ListItemText
                primary={user.displayName}
                secondary={
                  <div className={styles.secondaryRow}>
                    <Icon fontSize="small">account_circle</Icon>
                    <span className={styles.secondaryLabel}>
                      {getMail(user)}
                    </span>
                  </div>
                }
              />
            </ListItem>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={30} />}
        {errorMessage && (
          <Typography color="secondary">
            {errorStatusCode
              ? `HTTP ${errorStatusCode}: ${errorMessage}`
              : errorMessage}
          </Typography>
        )}
        <Button autoFocus onClick={onDialogClose} variant="outlined">
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={onSubmitClick}
          variant="contained"
          color="primary"
          disabled={
            users.length === 0 ||
            props.creativeWorkStatus === CreativeWorkStatus.ARCHIVED
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ListUsersDialog;
