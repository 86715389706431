import React, { useCallback, useEffect, useState, ChangeEvent } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Icon,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import ControlledFieldValue from "../../components/ControlledFieldValue";
import styles from "./UniverseVotersTargetingDialog.module.css";
import { setVotersTargetingStatus } from "../../services/ResourceService";
import withAuthorization from "../../security/withAuthorization";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  universeId: string;
}

function UniverseVotersTargetingDialog({
  open,
  onClose,
  title,
  universeId,
}: Props) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [targeted, setTargeted] = useState(false);
  const [file, setFile] = useState<File>();
  const [filename, setFilename] = useState<string>(
    ControlledFieldValue.DEFAULT
  );
  const [fileSize, setFileSize] = useState(0);
  const [loading, setLoading] = useState(false);

  const getFileSize = (bytes: number) => {
    const kilobytes = bytes / 1024;
    return kilobytes.toFixed(2);
  };

  const getFileStatus = (bytes: number) => {
    let fileStatus = undefined;

    if (bytes) {
      fileStatus = `${getFileSize(bytes)} KB`;
    }

    return fileStatus;
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileSelected = event.target.files[0];
      if (fileSelected) {
        setFile(fileSelected);
        setFilename(fileSelected.name);
        setFileSize(fileSelected.size);
      }
    }
  };

  const onTargetedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTargeted(event.target.checked);
  };

  useEffect(() => {
    if (filename?.length && file?.size) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [filename, file]);

  const onDialogClose = useCallback(() => {
    setSubmitDisabled(true);
    setFile(undefined);
    setFilename(ControlledFieldValue.DEFAULT);
    setFileSize(0);
    onClose();
  }, [onClose]);

  const saveResources = useCallback(
    async (accessToken) => {
      setLoading(true);
      setErrorMessage(undefined);
      try {
        if (file) {
          const targetingStatus = targeted ? 1 : 0;
          await setVotersTargetingStatus(
            accessToken,
            file,
            universeId,
            targetingStatus
          );
        }
        onDialogClose();
      } catch (error: any) {
        setErrorStatusCode(error["status"]);
        setErrorMessage("Processing Failed");
      } finally {
        setLoading(false);
      }
    },
    [file, universeId, targeted, onDialogClose, setLoading]
  );

  const onSubmitClick = async () => {
    setSubmitDisabled(true);
    withAuthorization(saveResources, () => {
      setErrorMessage("Unable to get Access Token");
      setSubmitDisabled(false);
    });
  };

  return (
    <Dialog onClose={onDialogClose} open={open} fullWidth>
      <DialogTitle>
        Update Targeting Status
        <Typography color="textSecondary">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <TextField
            label="Comma-Separated Values"
            variant="outlined"
            fullWidth
            value={filename}
            className={styles.field}
            helperText={getFileStatus(fileSize)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor="upload">
                    <input
                      className={styles.fileInput}
                      id="upload"
                      name="upload"
                      type="file"
                      accept="text/csv"
                      onChange={onFileChange}
                    />
                    <IconButton component="span">
                      <Icon>upload</Icon>
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            label="Targeted"
            control={
              <Checkbox
                color="primary"
                name="targeted"
                checked={targeted}
                onChange={onTargetedChange}
              />
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {errorMessage && (
          <Typography color="secondary">
            {errorStatusCode
              ? `HTTP ${errorStatusCode}: ${errorMessage}`
              : errorMessage}
          </Typography>
        )}
        {loading && <CircularProgress size={30} />}
        <Button autoFocus onClick={onDialogClose} variant="outlined">
          Cancel
        </Button>
        <Button
          autoFocus
          disabled={submitDisabled}
          onClick={onSubmitClick}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UniverseVotersTargetingDialog;
