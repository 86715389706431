import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  Icon,
  Toolbar,
  IconButton,
  Typography,
  List as MaterialList,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Button,
} from "@material-ui/core";
import withAuthorization from "../../security/withAuthorization";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import useCollections from "../../store/useCollections";
import styles from "./UniverseView.module.css";
import GranteeType from "../../store/models/GranteeType";
import TimeAgo from "react-timeago";
import { SearchAggregation } from "../../store/models/SearchAggregation";
import { Universe } from "../../store/models/Universe";
import { Voter } from "../../store/models/voter/Voter";
import { SearchMetaInformation } from "../../store/models/SearchMetaInformation";
import UniverseVotersTargetingDialog from "./UniverseVotersTargetingDialog";
import StatusAvatar from "../../components/StatusAvatar";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";

type MatchParams = {
  id: string;
};

const TARGETING_STATUS_ENABLED = "1";
const TARGETING_STATUS_DISABLED = "0";

function UniverseView({ match }: RouteComponentProps<MatchParams>) {
  const collections = useCollections();
  const [universe, setUniverse] = useState<Universe>();
  const [groupName, setGroupName] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);
  const [searchAggregations, setSearchAggregations] = useState<
    SearchAggregation[]
  >([]);
  const [targetingDialogOpen, setTargetingDialogOpen] =
    useState<boolean>(false);

  const onTargetingDialogClose = () => {
    setTargetingDialogOpen(false);
    getModels();
  };

  const onTargetingClick = () => {
    setTargetingDialogOpen(true);
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const getVotersOptions = (universeId: string) => {
    const queryFilter = {
      universeId: universeId,
    };
    const options = {
      queryParams: {
        filter: queryFilter,
        custom: [
          {
            key: "page[limit]",
            value: "1",
          },
        ],
      },
    };
    return options;
  };

  const getModels = useCallback(async () => {
    setLoading(true);
    const universeId = match.params.id;

    try {
      const foundUniverse = await collections.getOne(Universe, universeId);
      setUniverse(foundUniverse.data as Universe);

      const voterOptions = getVotersOptions(match.params.id);
      let foundVoters = await collections.getMany(Voter, voterOptions);
      if (foundVoters.meta) {
        const searchMetaInformation = foundVoters.meta as SearchMetaInformation;
        setSearchAggregations(searchMetaInformation.searchAggregations);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Loading Failed");
      setErrorMessage("Processing Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }, [match.params.id, collections]);

  useEffect(() => {
    if (universe) {
      const groupPermission = universe.permissions.find(
        (permission) => permission.grantee.granteeType === GranteeType.GROUP
      );
      if (groupPermission) {
        setGroupName(groupPermission.grantee.name);
      }
    }
  }, [universe]);

  useEffect(() => {
    withAuthorization(getModels, () => {
      setErrorTitle("Authorization Failed");
      setErrorMessage("Unable to get Access Token");
      setErrorOpen(true);
    });
  }, [getModels, collections]);

  const getQuantitativeValue = (universe: Universe, name: string) => {
    const quantitativeValue = universe.quantitativeValues.find(
      (quantitativeValue) => quantitativeValue.name === name
    );
    return quantitativeValue ? quantitativeValue.value : 0;
  };

  const getTargetingStatus = (targetingStatusTerm: string) => {
    const targetingStatus = searchAggregations.find(
      (aggregation) => aggregation.key === "targetingStatus"
    );
    let enabled = 0;
    if (targetingStatus) {
      const enabledSearchTerm = targetingStatus.searchTerms.find(
        (searchTerm) => searchTerm.term === targetingStatusTerm
      );
      if (enabledSearchTerm) {
        enabled = enabledSearchTerm.count;
      }
    }
    return enabled;
  };

  let container = undefined;
  if (universe) {
    const userPermission = universe.permissions.find(
      (permission) => permission.grantee.granteeType === GranteeType.USER
    );
    const username = userPermission?.grantee.name;
    const landValues = getQuantitativeValue(universe, "Land");
    const cellValues = getQuantitativeValue(universe, "Cell");
    const democratValue = getQuantitativeValue(universe, "D");
    const republicanValue = getQuantitativeValue(universe, "R");
    const independentValue = getQuantitativeValue(universe, "I");
    const ageValue = getQuantitativeValue(universe, "age");
    const targetingStatusEnabled = getTargetingStatus(TARGETING_STATUS_ENABLED);
    const targetingStatusDisabled = getTargetingStatus(
      TARGETING_STATUS_DISABLED
    );

    container = (
      <>
        <Toolbar disableGutters>
          <IconButton disabled>
            <StatusAvatar
              creativeWorkStatus={universe.creativeWorkStatus}
              icon="groups"
            />
          </IconButton>
          <div className={styles.headerLabel}>
            <Typography variant="h6">{universe.name}</Typography>
            <Typography color="textSecondary" variant="caption">
              Created <TimeAgo date={universe.dateCreated} />
            </Typography>
          </div>
        </Toolbar>
        <Grid container>
          <Grid item md={6}>
            <MaterialList>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>campaign</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Campaign" secondary={groupName} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>account_circle</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Creator" secondary={username} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>home</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Households"
                  secondary={universe.households}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>person</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Voters" secondary={universe.voters} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>radio_button_checked</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Voters Targeted"
                  secondary={targetingStatusEnabled}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>radio_button_unchecked</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Voters Detargeted"
                  secondary={targetingStatusDisabled}
                />
              </ListItem>
            </MaterialList>
          </Grid>
          <Grid item md={6}>
            <MaterialList>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>phone</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Home Phone Numbers"
                  secondary={landValues}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>smartphone</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Mobile Phone Numbers"
                  secondary={cellValues}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>D</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Registered Democrats"
                  secondary={democratValue}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>R</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Registered Republicans"
                  secondary={republicanValue}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>I</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Registered Independents"
                  secondary={independentValue}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Icon>cake</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Average Age" secondary={ageValue} />
              </ListItem>
            </MaterialList>
          </Grid>
        </Grid>
        <Toolbar disableGutters>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              className={styles.targetingButton}
              startIcon={<Icon>radio_button_checked</Icon>}
              onClick={onTargetingClick}
            >
              Targeting
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              disabled={
                universe.creativeWorkStatus === CreativeWorkStatus.ARCHIVED
              }
              to={`/lists/new?universeId=${universe.id}`}
            >
              New List
            </Button>
          </Grid>
        </Toolbar>
      </>
    );
  }

  return (
    <div>
      {container}
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      {universe ? (
        <UniverseVotersTargetingDialog
          open={targetingDialogOpen}
          onClose={onTargetingDialogClose}
          title={universe.name}
          universeId={universe.id}
        />
      ) : undefined}
    </div>
  );
}

export default UniverseView;
