import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import { DigitalDocumentPermission } from "./DigitalDocumentPermission";

export class GroupMember extends jsonapi(Model) {
  static type = "group-members";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public directoryObjectId!: string;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;
}
