import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import { Answer } from "../../store/models/Answer";
import styles from "./QuestionCard.module.css";
import { Question } from "../../store/models/Question";
import QuestionType from "../../store/models/QuestionType";
import { useState } from "react";
import QuestionPurposeType from "../../store/models/QuestionPurposeType";

type Props = {
  className: string;
  question: Question;
  questionNumber: number;
  questionId: string;
  removeDisabled: boolean;
  onAddQuestion?: (questionNumber: number) => void;
  onRemoveQuestion?: (questionId: string) => void;
};

function QuestionCard(props: Props) {
  const identificationEnabled =
    props.question.questionPurposeType === QuestionPurposeType.IDENTIFICATION;

  const [answers, setAnswers] = useState(props.question.answers);
  const [questionType, setQuestionType] = useState(props.question.questionType);
  const [
    questionPurposeTypeIdentification,
    setQuestionPurposeTypeIdentification,
  ] = useState(identificationEnabled);
  const editDisabled = props.onAddQuestion === undefined;

  const onQuestionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onAddQuestion) {
      props.question.questionType = event.target.value as QuestionType;
      setQuestionType(props.question.questionType);
    }
  };

  const onIdentificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setQuestionPurposeTypeIdentification(checked);
    if (checked) {
      props.question.questionPurposeType = QuestionPurposeType.IDENTIFICATION;
    } else {
      props.question.questionPurposeType = QuestionPurposeType.SURVEY;
    }
  };

  const onAnswerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.name;
    const answer = props.question.answers.find((answer) => answer.id === id);
    if (answer) {
      answer.name = event.target.value;
    }
  };

  const onAddAnswerClick = () => {
    const answer = new Answer();
    answer.id = uuid();
    answer.dateCreated = new Date().toISOString();
    answer.datePublished = new Date().toISOString();

    props.question.answers = [...props.question.answers, answer];
    setAnswers(props.question.answers);
  };

  const onRemoveAnswerClick = (answer: Answer) => {
    const updatedAnswers = props.question.answers.filter((currentAnswer) => {
      return currentAnswer.id !== answer.id;
    });
    props.question.answers = updatedAnswers;
    setAnswers(props.question.answers);
  };

  const onAddQuestionClick = () => {
    if (props.onAddQuestion) {
      props.onAddQuestion(props.questionNumber);
    }
  };

  const onRemoveQuestionClick = () => {
    if (props.onRemoveQuestion) {
      props.onRemoveQuestion(props.questionId);
    }
  };

  const onQuestionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.question.name = event.target.value;
  };

  const questionButtons = editDisabled ? undefined : (
    <>
      <Button
        variant="outlined"
        className={styles.removeButton}
        disabled={props.removeDisabled}
        onClick={onRemoveQuestionClick}
      >
        Remove
      </Button>
      <Button variant="contained" onClick={onAddQuestionClick}>
        Add
      </Button>
    </>
  );

  return (
    <Card className={props.className}>
      <CardHeader
        title={
          <Grid container>
            <Grid item md={7}>
              <TextField
                label={`Question ${props.questionNumber}`}
                variant="outlined"
                className={styles.questionField}
                defaultValue={props.question.name}
                fullWidth
                disabled={editDisabled}
                onChange={onQuestionNameChange}
                inputProps={{
                  className: styles.textField,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">chat_bubble</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                label="Question Type"
                variant="outlined"
                fullWidth
                select
                disabled={editDisabled}
                value={questionType}
                onChange={onQuestionTypeChange}
                inputProps={{
                  className: styles.textField,
                }}
              >
                <MenuItem value={QuestionType.SINGLE_ANSWER}>
                  Single Answer
                </MenuItem>
                <MenuItem value={QuestionType.MULTIPLE_ANSWER}>
                  Multiple Answer
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item md={2}>
              <FormControlLabel
                label="Identification"
                className={styles.identificationPurposeLabel}
                control={
                  <Checkbox
                    color="primary"
                    name="questionPurposeTypeIdentification"
                    checked={questionPurposeTypeIdentification}
                    onChange={onIdentificationChange}
                  />
                }
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {answers.map((answer, index) => (
          <TextField
            key={answer.id}
            name={answer.id}
            defaultValue={answer.name}
            label={`Answer ${index + 1}`}
            variant="outlined"
            className={styles.answerField}
            onChange={onAnswerNameChange}
            disabled={editDisabled}
            fullWidth
            inputProps={{
              className: styles.textField,
            }}
            InputProps={{
              endAdornment: editDisabled ? undefined : (
                <InputAdornment position="end">
                  {index === answers.length - 1 ? (
                    <IconButton onClick={onAddAnswerClick}>
                      <Icon color="primary">add_circle</Icon>
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => onRemoveAnswerClick(answer)}>
                      <Icon>remove_circle</Icon>
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        ))}
        {questionButtons}
      </CardContent>
    </Card>
  );
}

export default QuestionCard;
