export class Answer {
  public id!: string;

  public name!: string;

  public version: number = 1;

  public dateCreated!: string;

  public datePublished!: string;
}
