import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";

export class Campaign extends jsonapi(Model) {
  static type = "campaigns";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public description!: string;
}
