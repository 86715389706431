import React, { useCallback, useState, useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import ControlledFieldValue from "../../components/ControlledFieldValue";
import { Universe } from "../../store/models/Universe";
import useCollections from "../../store/useCollections";
import withAuthorization from "../../security/withAuthorization";
import { getSessionGroupId } from "../../services/SessionStorageService";
import { getRequestOptions } from "../../services/ResourceSearchService";

type Props = {
  onUniverseChangeHandler: (universeId: string, voters: number) => void;
  onUniversesFound: (universesFound: number) => void;
  initialUniverseId?: string;
};

function UniverseField({
  onUniverseChangeHandler,
  onUniversesFound,
  initialUniverseId,
}: Props) {
  const collections = useCollections();
  const [universeId, setUniverseId] = useState<string>(
    ControlledFieldValue.DEFAULT
  );
  const [universes, setUniverses] = useState<Universe[]>([]);
  const [disabled, setDisabled] = useState(false);

  const onUniverseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUniverseId(event.target.value);
  };

  const getModels = useCallback(async () => {
    try {
      const sessionGroupId = getSessionGroupId();
      const groupId = sessionGroupId ? sessionGroupId : undefined;
      const search = undefined;

      const options = getRequestOptions(search, groupId);
      const statusFilter = {
        key: "filter[creativeWorkStatus][NEQ]",
        value: "ARCHIVED",
      };
      options.queryParams?.custom?.push(statusFilter);

      const response = await collections.getMany(Universe, options);
      const records = response.data;
      if (Array.isArray(records)) {
        setUniverses(records);
        if (records.length) {
          const initialUniverse = records.find(
            (record) => record.id === initialUniverseId
          );
          if (initialUniverse) {
            setUniverseId(initialUniverse.id);
          } else {
            const defaultUniverseId = records[0].id;
            setUniverseId(defaultUniverseId);
          }
        }
      }
    } catch (e) {
      setUniverses([]);
      setDisabled(true);
    }
  }, [collections, initialUniverseId]);

  useEffect(() => {
    withAuthorization(getModels, () => {
      setUniverses([]);
      setDisabled(true);
    });
  }, [getModels]);

  useEffect(() => {
    const universe = universes.find((universe) => universe.id === universeId);
    const voters = universe ? universe.voters : 0;
    onUniverseChangeHandler(universeId, voters);
  }, [universes, universeId, onUniverseChangeHandler]);

  useEffect(() => {
    if (universes.length === 0 || universes.length === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    onUniversesFound(universes.length);
  }, [universes, onUniversesFound]);

  return (
    <TextField
      label="Universe"
      variant="outlined"
      disabled={disabled}
      fullWidth
      select
      value={universeId}
      onChange={onUniverseChange}
    >
      {universes.map((universe) => (
        <MenuItem key={universe.id} value={universe.id}>
          {universe.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default UniverseField;
