import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import { ActionObject } from "./action/ActionObject";
import { ActionLocation } from "./action/ActionLocation";
import { ActionParticipant } from "./action//ActionParticipant";
import { SurveyResult } from "./action/SurveyResult";
import { ReferenceIdentifier } from "./ReferenceIdentifier";

export class ReplyAction extends jsonapi(Model) {
  static type = "reply-actions";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public version!: number;

  @Attribute()
  public startTime!: string;

  @Attribute()
  public endTime!: string;

  @Attribute()
  public actionStatus!: ReferenceIdentifier;

  @Attribute()
  public agent!: ReferenceIdentifier;

  @Attribute()
  public location!: ActionLocation;

  @Attribute()
  public participant!: ActionParticipant;

  @Attribute()
  public actionObject!: ActionObject;

  @Attribute()
  public result!: SurveyResult;
}
