import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import LogoContainer from "./LogoContainer";

function Home() {
  return (
    <Container maxWidth="xs">
      <Grid container justify="center">
        <LogoContainer />
        <Typography></Typography>
      </Grid>
    </Container>
  );
}

export default Home;
