import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { Group } from "@microsoft/microsoft-graph-types";
import {
  getMemberOfGroups,
  getEnabledGroups,
} from "../../services/GraphService";
import ControlledFieldValue from "../../components/ControlledFieldValue";
import ApiPermission from "../../security/ApiPermission";
import useApiPermissionGranted from "../../security/useApiPermissionGranted";
import {
  getSessionGroupId,
  setSessionGroupId,
} from "../../services/SessionStorageService";

type Props = {
  onCampaignChangeHandler: (groupId: string, groupName: string) => void;
  onGroupsFound: (groupsFound: number) => void;
  initialGroupId?: string;
};

function CampaignField({
  onCampaignChangeHandler,
  onGroupsFound,
  initialGroupId,
}: Props) {
  const groupPermissionGranted = useApiPermissionGranted(
    ApiPermission.GROUP_READ_WRITE_ALL
  );
  const [groupId, setGroupId] = useState<string>(ControlledFieldValue.DEFAULT);
  const [groups, setGroups] = useState<Group[]>([]);
  const [disabled, setDisabled] = useState(false);

  const onCampaignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sessionGroupId = event.target.value;
    setGroupId(sessionGroupId);
    setSessionGroupId(sessionGroupId);
  };

  const getDefaultGroupId = (groups: Group[]) => {
    let defaultGroupId = groups[0].id!;
    const sessionGroupId = getSessionGroupId();
    if (sessionGroupId) {
      const foundGroup = groups.find((group) => group.id === sessionGroupId);
      if (foundGroup) {
        defaultGroupId = foundGroup.id!;
      }
    }
    return defaultGroupId;
  };

  useEffect(() => {
    const promise = groupPermissionGranted
      ? getEnabledGroups()
      : getMemberOfGroups();
    promise
      .then((memberOfGroups) => {
        setGroups(memberOfGroups);
        if (memberOfGroups.length) {
          const initialGroup = memberOfGroups.find(
            (group) => group.id === initialGroupId
          );
          if (initialGroup) {
            setGroupId(initialGroupId!);
          } else {
            const defaultGroupId = getDefaultGroupId(memberOfGroups);
            setGroupId(defaultGroupId);
          }
        }
      })
      .catch((e) => setDisabled(true));
  }, [initialGroupId, groupPermissionGranted]);

  useEffect(() => {
    const groupName = groups.find((group) => group.id === groupId)?.displayName;
    if (groupName) {
      onCampaignChangeHandler(groupId, groupName);
    }
  }, [groupId, groups, onCampaignChangeHandler]);

  useEffect(() => {
    if (groups.length === 0 || groups.length === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    onGroupsFound(groups.length);
  }, [groups, onGroupsFound]);

  return (
    <TextField
      label="Campaign"
      variant="outlined"
      disabled={disabled}
      fullWidth
      select
      value={groupId}
      onChange={onCampaignChange}
    >
      {groups.map((group) => (
        <MenuItem key={group.id} value={group.id}>
          {group.displayName}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default CampaignField;
