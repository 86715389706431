import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Logo from "../images/logo.svg";
import styles from "./LogoContainer.module.css";

function LogoContainer() {
  return (
    <Grid container justify="center">
      <img src={Logo} alt="Trireme Logo" className={styles.logo} />
      <Typography variant="h3" color="primary" className={styles.heading}>
        Trireme
      </Typography>
    </Grid>
  );
}

export default LogoContainer;
