import React, { useState } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import styles from "./ListVoterFilter.module.css";
import { SearchTerm } from "../../store/models/SearchTerm";
import { SearchAggregation } from "../../store/models/SearchAggregation";
import SearchTermSelect from "./SearchTermSelect";

const EMPTY = "";

type Props = {
  onFilterChange: (filter: Record<string, string | string[]>) => void;
  searchAggregations: SearchAggregation[];
};

function ListVoterFilter(props: Props) {
  const [gender, setGender] = useState(EMPTY);
  const [party, setParty] = useState(EMPTY);
  const [precincts, setPrecincts] = useState<string[]>([]);
  const [city, setCity] = useState<string>(EMPTY);
  const [county, setCounty] = useState<string>(EMPTY);
  const [postalCode, setPostalCode] = useState<string>(EMPTY);

  const getSearchTerms = (key: string) => {
    let searchTerms: SearchTerm[] = [];

    const foundSearchAggregation = props.searchAggregations.find(
      (searchAggregation) => searchAggregation.key === key
    );
    if (foundSearchAggregation) {
      searchTerms = foundSearchAggregation.searchTerms;
    }

    return searchTerms;
  };

  const onClearClick = () => {
    setGender(EMPTY);
    setParty(EMPTY);
    setPrecincts([]);
    setCity(EMPTY);
    setCounty(EMPTY);
    setPostalCode(EMPTY);
    props.onFilterChange({});
  };

  const onApplyClick = () => {
    const filter: Record<string, string | string[]> = {};

    if (gender.length) {
      filter["person.gender"] = gender;
    }
    if (party.length) {
      filter["registration.party"] = party;
    }
    if (precincts.length) {
      filter["registration.precinct"] = precincts;
    }
    if (city?.length) {
      filter["person.homeLocation.address.addressLocality"] = city;
    }
    if (county?.length) {
      filter["registration.county"] = county;
    }
    if (postalCode?.length) {
      filter["person.homeLocation.address.postalCode"] = postalCode;
    }

    props.onFilterChange(filter);
  };

  const onGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGender(event.target.value as string);
  };

  const onPartyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setParty(event.target.value as string);
  };

  const onPrecinctChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPrecincts(event.target.value as string[]);
  };

  const onCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCity(event.target.value as string);
  };

  const onCountyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCounty(event.target.value as string);
  };

  const onPostalCodeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPostalCode(event.target.value as string);
  };

  return (
    <Paper variant="outlined" className={styles.filterPaper}>
      <Grid container spacing={1}>
        <Grid item lg={2}>
          <SearchTermSelect
            label="Gender"
            value={gender}
            searchTerms={getSearchTerms("gender")}
            onValueChange={onGenderChange}
          />
        </Grid>
        <Grid item lg={1}>
          <SearchTermSelect
            label="Party"
            value={party}
            searchTerms={getSearchTerms("party")}
            onValueChange={onPartyChange}
          />
        </Grid>
        <Grid item lg={2}>
          <SearchTermSelect
            label="County"
            value={county}
            searchTerms={getSearchTerms("county")}
            onValueChange={onCountyChange}
          />
        </Grid>
        <Grid item lg={2}>
          <SearchTermSelect
            label="Postal Code"
            value={postalCode}
            searchTerms={getSearchTerms("postalCode")}
            onValueChange={onPostalCodeChange}
          />
        </Grid>
        <Grid item lg={2}>
          <SearchTermSelect
            label="City"
            value={city}
            searchTerms={getSearchTerms("addressLocality")}
            onValueChange={onCityChange}
          />
        </Grid>
        <Grid item lg={1}>
          <SearchTermSelect
            label="Precincts"
            value={precincts}
            searchTerms={getSearchTerms("precinct")}
            onValueChange={onPrecinctChange}
            multiple
          />
        </Grid>
        <Grid item lg={2}>
          <div className={styles.filterActions}>
            <Button variant="outlined" size="medium" onClick={onClearClick}>
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={styles.applyButton}
              onClick={onApplyClick}
            >
              Apply
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ListVoterFilter;
