import useSectionHeading from "./useSectionHeading";

const applicationHeading = "Trireme";

const usePageHeading = () => {
  const sectionHeading = useSectionHeading();
  let pageHeading = applicationHeading;
  if (sectionHeading) {
    pageHeading = `${sectionHeading} - ${applicationHeading}`;
  }
  return pageHeading;
};

export default usePageHeading;
