import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import { DigitalDocumentPermission } from "../DigitalDocumentPermission";
import { Demographics } from "./Demographics";
import { Person } from "./Person";
import { Registration } from "./Registration";
import { Targeting } from "./Targeting";

export class Voter extends jsonapi(Model) {
  static type = "voters";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public version: number = 1;

  @Attribute()
  public dateCreated!: Date;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;

  @Attribute()
  public universeId!: string;

  @Attribute()
  public person!: Person;

  @Attribute()
  public registration!: Registration;

  @Attribute()
  public targeting!: Targeting;

  @Attribute()
  public demographics!: Demographics;
}
