import { Grantee } from "../store/models/Grantee";
import { Universe } from "../store/models/Universe";

const config = {
  baseUrl: "/trireme-resource-api/",
};

if (process.env.REACT_APP_RESOURCE_API_BASE_URL) {
  config.baseUrl = process.env.REACT_APP_RESOURCE_API_BASE_URL;
}

export async function setVoters(
  accessToken: string,
  commaSeparatedValues: File,
  name: string,
  grantee: Grantee
) {
  const response = await fetch(`${config.baseUrl}resources/voters`, {
    method: "POST",
    headers: {
      "Content-Type": "text/csv",
      Authorization: `Bearer ${accessToken}`,
      Name: name,
      "Grantee-Id": grantee.id,
      "Grantee-Name": grantee.name,
    },
    body: commaSeparatedValues,
  });
  if (response.ok) {
    return new Universe(await response.json());
  } else {
    throw Error(`Processing Failed: ${response.status}`);
  }
}

export async function setVotersTargetingStatus(
  accessToken: string,
  commaSeparatedValues: File,
  universeId: string,
  targetingStatus: number
) {
  const response = await fetch(
    `${config.baseUrl}resources/universes/${universeId}/voters/targeting`,
    {
      method: "POST",
      headers: {
        "Content-Type": "text/csv",
        Authorization: `Bearer ${accessToken}`,
        "Targeting-Status": targetingStatus.toString(),
      },
      body: commaSeparatedValues,
    }
  );
  if (response.ok) {
    return new Universe(await response.json());
  } else {
    throw Error(`Processing Failed: ${response.status}`);
  }
}
