import React, { useEffect, useState } from "react";
import { Group } from "@microsoft/microsoft-graph-types";
import SearchBar from "../../components/SearchBar";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import CampaignCard from "./CampaignCard";
import { observable } from "mobx";
import { getMemberOfGroups, getGroups } from "../../services/GraphService";
import ApiPermission from "../../security/ApiPermission";
import useApiPermissionGranted from "../../security/useApiPermissionGranted";

const DISABLED_DESCRIPTION = "DISABLED";

function Campaigns() {
  const groupPermissionGranted = useApiPermissionGranted(
    ApiPermission.GROUP_READ_WRITE_ALL
  );

  const [groups, setGroups] = useState<Group[]>(observable<Group>([]));
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onSearchChangeHandler = (requestedSearch: string) => {
    if (requestedSearch.length) {
      setSearch(requestedSearch);
    } else {
      setSearch(undefined);
    }
  };

  useEffect(() => {
    setLoading(true);
    setGroups([]);
    const promise = groupPermissionGranted
      ? getGroups(search)
      : getMemberOfGroups(search);
    promise
      .then((groupsFound) => setGroups(groupsFound))
      .catch((error) => {
        setErrorStatusCode(error.statusCode);
        setErrorTitle("Loading Failed");
        if (error.message) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage("Processing Failed");
        }
        setErrorOpen(true);
      })
      .finally(() => setLoading(false));
  }, [search, groupPermissionGranted]);

  return (
    <div>
      <SearchBar
        addButtonLink={"/campaigns/new"}
        total={groups.length}
        onSearchChangeHandler={onSearchChangeHandler}
        addApiPermissionRequired={ApiPermission.GROUP_READ_WRITE_ALL}
      />
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      {groups.map((group) => (
        <CampaignCard
          key={group.id}
          groupId={group.id!}
          title={group.displayName!}
          created={group.createdDateTime!}
          disabled={group.mailNickname === DISABLED_DESCRIPTION}
        />
      ))}
    </div>
  );
}

export default Campaigns;
