import { ParseResult } from "papaparse";
import Papa from "papaparse";

export function parseCommaSeparatedValues(
  file: File,
  onParseComplete: (data: object[], errors: object) => void
) {
  const complete = (parseResult: ParseResult<object>) => {
    onParseComplete(parseResult.data, parseResult.errors);
  };

  Papa.parse(file, {
    skipEmptyLines: true,
    complete: complete,
  });
}
