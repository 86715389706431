import { createContext, ReactNode } from "react";
import { CachingStrategy, config } from "@datx/jsonapi";
import AppCollection from "./AppCollection";

if (process.env.REACT_APP_RESOURCE_API_BASE_URL) {
  config.baseUrl = process.env.REACT_APP_RESOURCE_API_BASE_URL;
} else {
  config.baseUrl = "/trireme-resource-api/";
}

config.cache = CachingStrategy.NetworkOnly;

interface IProviderProps {
  children: ReactNode;
}

const appCollection = new AppCollection();

export const CollectionContext = createContext(appCollection);

export const CollectionProvider = ({ children }: IProviderProps) => {
  return (
    <CollectionContext.Provider value={appCollection}>
      {children}
    </CollectionContext.Provider>
  );
};
