import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Toolbar,
} from "@material-ui/core";
import AppRoleGranted from "../security/AppRoleGranted";
import AppRole from "../security/AppRole";
import styles from "./NavigationDrawer.module.css";

export default function NavigationDrawer() {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      className={styles.drawer}
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <div className={styles.drawerContainer}>
        <Toolbar />
        <List>
          <ListItem
            button
            component={Link}
            to="/campaigns"
            selected={location.pathname.startsWith("/campaigns")}
          >
            <ListItemIcon>
              <Icon>campaign</Icon>
            </ListItemIcon>
            <ListItemText primary="Campaigns" />
          </ListItem>
          <AppRoleGranted appRole={AppRole.UNIVERSE_READ}>
            <ListItem
              button
              component={Link}
              to="/universes"
              selected={location.pathname.startsWith("/universes")}
            >
              <ListItemIcon>
                <Icon>groups</Icon>
              </ListItemIcon>
              <ListItemText primary="Universes" />
            </ListItem>
          </AppRoleGranted>
          <ListItem
            button
            component={Link}
            to="/lists"
            selected={location.pathname.startsWith("/lists")}
          >
            <ListItemIcon>
              <Icon>assignment</Icon>
            </ListItemIcon>
            <ListItemText primary="Lists" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/surveys"
            selected={location.pathname.startsWith("/surveys")}
          >
            <ListItemIcon>
              <Icon>question_answer</Icon>
            </ListItemIcon>
            <ListItemText primary="Surveys" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/users"
            selected={location.pathname.startsWith("/users")}
          >
            <ListItemIcon>
              <Icon>account_circle</Icon>
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}
