import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  statusCode: number;
}

function ErrorDialog(props: Props) {
  return (
    <Dialog onClose={props.onClose} open={props.open} fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography paragraph>{props.message}</Typography>
          {props.statusCode && (
            <Typography variant="body2">
              HTTP Status Code: {props.statusCode}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
