import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Card, CardHeader, Chip, Icon, Link } from "@material-ui/core";
import styles from "./UserCard.module.css";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import AccountStatus from "../../store/models/AccountStatus";
import ScheduleChip from "../../components/ScheduleChip";

type Props = {
  title: string;
  username: string;
  accountStatus: AccountStatus;
  userId: string;
  created?: string;
};

function UserCard(props: Props) {
  let avatar = (
    <Avatar>
      <Icon>person</Icon>
    </Avatar>
  );
  if (props.accountStatus === AccountStatus.ENABLED) {
    avatar = (
      <PrimaryAvatar>
        <Icon>person</Icon>
      </PrimaryAvatar>
    );
  } else if (props.accountStatus === AccountStatus.PENDING_ACCEPTANCE) {
    avatar = (
      <Avatar>
        <Icon>email</Icon>
      </Avatar>
    );
  }

  return (
    <Card className={styles.userCard}>
      <CardHeader
        title={
          <Link component={RouterLink} to={`/users/${props.userId}`}>
            {props.title}
          </Link>
        }
        subheader={
          <div>
            <Chip
              label={props.username}
              className={styles.chip}
              size="small"
              variant="outlined"
              icon={<Icon fontSize="small">email</Icon>}
            />
            {props.created && (
              <ScheduleChip label="Created" dateTime={props.created} />
            )}
          </div>
        }
        avatar={<RouterLink to={`/users/${props.userId}`}>{avatar}</RouterLink>}
      />
    </Card>
  );
}

export default UserCard;
