import React, { ReactNode, useRef } from "react";
import { Paper } from "@material-ui/core";
import {
  FeatureGroup as LeafletFeatureGroup,
  LatLngTuple,
  LayerGroup,
  Map,
  Polyline,
} from "leaflet";
import {
  FeatureGroup,
  MapContainer,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import styles from "./PaperMap.module.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

const minimumZoom = 4;
const defaultCenter: LatLngTuple = [39.83, -98.58];

interface Props {
  children?: ReactNode;
  whenReady?: (map: Map) => void;
  onDrawCreated?: (layer: Polyline, layerId: number) => void;
  onDrawDeleted?: (layerGroup: LayerGroup) => void;
  onDrawEdited?: (layerGroup: LayerGroup) => void;
}

function PaperMap({
  children,
  whenReady = () => {},
  onDrawCreated = () => {},
  onDrawDeleted = () => {},
  onDrawEdited = () => {},
}: Props) {
  const drawFeatureGroupRef = useRef<LeafletFeatureGroup>(null);

  const onCreated = (created: any) => {
    const layer = created.layer as Polyline;
    const layerId = drawFeatureGroupRef.current?.getLayerId(layer);
    if (layerId) {
      onDrawCreated(layer, layerId);
    }
  };

  const onDeleted = (deleted: any) => {
    const layerGroup = deleted.layers as LayerGroup;
    onDrawDeleted(layerGroup);
  };

  const onEdited = (edited: any) => {
    const layerGroup = edited.layers as LayerGroup;
    onDrawEdited(layerGroup);
  };

  return (
    <Paper variant="outlined">
      <MapContainer
        center={defaultCenter}
        className={styles.mapContainer}
        zoom={minimumZoom}
        minZoom={minimumZoom}
        zoomControl={false}
        preferCanvas={true}
        whenCreated={whenReady}
      >
        <ZoomControl position="topright" />
        <ScaleControl metric={false} />
        <TileLayer
          attribution='<a href="https://openstreetmap.org">OpenStreetMap</a>
          and <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
        />
        {children}
        <FeatureGroup ref={drawFeatureGroupRef}>
          <EditControl
            position="topright"
            draw={{
              circle: false,
              circlemarker: false,
              marker: false,
              polyline: false,
              rectangle: {
                repeatMode: true,
              },
              polygon: {
                repeatMode: true,
              },
            }}
            onCreated={onCreated}
            onDeleted={onDeleted}
            onEdited={onEdited}
          />
        </FeatureGroup>
      </MapContainer>
    </Paper>
  );
}

export default PaperMap;
