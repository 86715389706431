import AppRole from "./AppRole";

export const AppRoles = new Map([
  [
    AppRole.APP_ROLE_ASSIGNMENT_READ_WRITE,
    "4462fe27-c8c1-42da-824c-7519752cfd80",
  ],
  [AppRole.GROUP_MEMBER_READ_WRITE, "b7f6ea15-ce87-439a-8077-cd665def08f6"],
  [AppRole.LIST_READ_WRITE, "a5b974fd-d3ef-e7e0-72be-e98a00220a53"],
  [AppRole.SURVEY_READ_WRITE, "2b9e9a27-da7f-fbfe-ba46-86cdfad17115"],
  [AppRole.UNIVERSE_READ, "5fee5e60-4344-dc84-67ac-27ec0372771e"],
  [AppRole.UNIVERSE_READ_WRITE, "61f2db77-6890-5ec9-5b1d-aebfc32d6d63"],
]);

export const AppRoleIds = new Map<string, AppRole>();
AppRoles.forEach((appRoleId, appRole) => AppRoleIds.set(appRoleId, appRole));

export function getAppRoleLabel(appRole: AppRole) {
  return appRole
    .replaceAll(".", "-")
    .replaceAll("Group", "Campaign")
    .replaceAll("ReadWrite", "Read and Edit");
}
