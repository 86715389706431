import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Toolbar,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import withAuthorization from "../../security/withAuthorization";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import useCollections from "../../store/useCollections";
import { Survey } from "../../store/models/Survey";
import styles from "./SurveyView.module.css";
import GranteeType from "../../store/models/GranteeType";
import QuestionCard from "./QuestionCard";
import TimeAgo from "react-timeago";
import { saveCommaSeparatedValues } from "../../services/FileDownloadService";
import StatusAvatar from "../../components/StatusAvatar";

type MatchParams = {
  id: string;
};

const STANDARD_QUESTION_ID = "def000";

function SurveyView({ match }: RouteComponentProps<MatchParams>) {
  const collections = useCollections();
  const [survey, setSurvey] = useState<Survey>();
  const [groupName, setGroupName] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const getModels = useCallback(async () => {
    setLoading(true);
    const surveyId = match.params.id;

    try {
      const foundSurvey = await collections.getOne(Survey, surveyId);
      setSurvey(foundSurvey.data as Survey);
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Loading Failed");
      setErrorMessage("Processing Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }, [match.params.id, collections]);

  const onDownloadClick = () => {
    if (survey) {
      const records = survey?.questions
        .filter((question) => !question.id.startsWith(STANDARD_QUESTION_ID))
        .map((question) => {
          const record = [];
          record.push(question.name);

          question.answers.forEach((answer) => {
            record.push(answer.name);
          });

          return record;
        });
      saveCommaSeparatedValues(records, undefined, survey?.name);
    }
  };

  useEffect(() => {
    if (survey) {
      const groupPermission = survey.permissions.find(
        (permission) => permission.grantee.granteeType === GranteeType.GROUP
      );
      if (groupPermission) {
        setGroupName(groupPermission.grantee.name);
      }
    }
  }, [survey]);

  useEffect(() => {
    withAuthorization(getModels, () => {
      setErrorTitle("Authorization Failed");
      setErrorMessage("Unable to get Access Token");
      setErrorOpen(true);
    });
  }, [getModels, collections]);

  let container = undefined;
  if (survey) {
    const script = survey.script?.paragraphs.join("\r\n");

    container = (
      <>
        <Toolbar disableGutters>
          <IconButton disabled>
            <StatusAvatar
              creativeWorkStatus={survey.creativeWorkStatus}
              icon="question_answer"
            />
          </IconButton>
          <div className={styles.headerLabel}>
            <Typography variant="h6">{survey.name}</Typography>
            <Typography color="textSecondary" variant="caption">
              Created <TimeAgo date={survey.dateCreated} />
            </Typography>
          </div>
          <Button
            component="span"
            variant="contained"
            disabled={survey === undefined}
            onClick={onDownloadClick}
          >
            Download
          </Button>
        </Toolbar>
        <form autoComplete="off" className={styles.formSection}>
          <TextField
            label="Campaign"
            variant="outlined"
            fullWidth
            className={styles.field}
            value={groupName}
          />
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            className={styles.field}
            value={survey.name}
          />
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            className={styles.field}
            value={survey.description}
          />
          <TextField
            label="Script"
            variant="outlined"
            fullWidth
            multiline
            minRows={10}
            className={styles.field}
            value={script}
          />
        </form>
        {survey.questions.map((question, index) => (
          <QuestionCard
            key={question.id}
            question={question}
            questionId={question.id}
            questionNumber={index + 1}
            className={styles.questionCard}
            removeDisabled={true}
          />
        ))}
      </>
    );
  }

  return (
    <div>
      {container}
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
    </div>
  );
}

export default SurveyView;
