import React from "react";
import { Chip, Icon } from "@material-ui/core";
import TimeAgo from "react-timeago";
import styles from "./ScheduleChip.module.css";

interface Props {
  label?: string;
  dateTime: Date | string;
}

function ScheduleChip(props: Props) {
  return (
    <Chip
      label={
        <span>
          {props.label}{" "}
          <TimeAgo date={props.dateTime} title={props.dateTime.toString()} />
        </span>
      }
      className={styles.chip}
      size="small"
      variant="outlined"
      icon={<Icon fontSize="small">schedule</Icon>}
    />
  );
}

export default ScheduleChip;
