import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import styles from "./CancelButton.module.css";

interface Props {
  buttonLink: string;
}

function CancelButton(props: Props) {
  return (
    <Button
      variant="outlined"
      size="large"
      className={styles.cancelButton}
      component={Link}
      to={props.buttonLink}
    >
      Cancel
    </Button>
  );
}

export default CancelButton;
