import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { Universe } from "../../store/models/Universe";
import useLocationSearch from "../../core/hooks/useLocationSearch";
import useCollections from "../../store/useCollections";
import AppRole from "../../security/AppRole";
import UniverseCard from "./UniverseCard";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import withAuthorization from "../../security/withAuthorization";
import LoadingProgress from "../../components/LoadingProgress";
import { getRequestOptions } from "../../services/ResourceSearchService";
import styles from "./Universes.module.css";
import CampaignField from "../users/CampaignField";

function Universes() {
  const locationSearch = useLocationSearch();
  let searchGroupId = locationSearch.get("groupId");
  const initialGroupId = searchGroupId ? searchGroupId : undefined;

  const collections = useCollections();
  const [universes, setUniverses] = useState<Universe[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onSearchChangeHandler = (requestedSearch: string) => {
    if (requestedSearch.length) {
      setSearch(requestedSearch);
    } else {
      setSearch(undefined);
    }
  };

  const getModels = useCallback(async () => {
    setLoading(true);
    try {
      const options = getRequestOptions(search, groupId);
      const response = await collections.getMany(Universe, options);
      if (Array.isArray(response.data)) {
        setUniverses(response.data);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Loading Failed");
      setErrorMessage("Processing Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }, [collections, search, groupId]);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onCampaignChange = (campaignGroupId: string) => {
    setGroupId(campaignGroupId);
  };

  const onGroupsFound = (groupsFound: number) => {
    if (groupsFound === 0) {
      setLoading(false);
    }
  };

  const onChange = async (universe: Universe) => {
    try {
      await universe.save();
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Save Failed");
      setErrorMessage(`Universe Status update failed`);
      setErrorOpen(true);
    }
  };

  useEffect(() => {
    if (groupId) {
      withAuthorization(getModels, () => {
        setErrorTitle("Authorization Failed");
        setErrorMessage("Unable to get Access Token");
        setErrorOpen(true);
      });
    }
  }, [getModels, collections, search, groupId]);

  return (
    <div>
      <SearchBar
        addButtonLink={"/universes/new"}
        total={universes.length}
        onSearchChangeHandler={onSearchChangeHandler}
        addAppRoleRequired={AppRole.UNIVERSE_READ_WRITE}
      />
      <div className={styles.campaignField}>
        <CampaignField
          onCampaignChangeHandler={onCampaignChange}
          onGroupsFound={onGroupsFound}
          initialGroupId={initialGroupId}
        />
      </div>
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      {universes.map((universe) => (
        <UniverseCard
          key={universe.id}
          universe={universe}
          onChange={onChange}
        />
      ))}
    </div>
  );
}

export default Universes;
