import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import { Redirect } from "react-router";
import {
  Toolbar,
  Typography,
  Icon,
  IconButton,
  Grid,
  FormControlLabel,
  Paper,
  TextField,
  List as MaterialList,
  ListItem,
  InputAdornment,
  Collapse,
  Switch,
} from "@material-ui/core";
import { v4 as uuid } from "uuid";
import CancelButton from "../../components/buttons/CancelButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import PaperMap from "../../components/PaperMap";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import ProcessingDialog from "../../components/dialogs/ProcessingDialog";
import withAuthorization from "../../security/withAuthorization";
import useAccountInfo from "../../security/useAccountInfo";
import { Grantee } from "../../store/models/Grantee";
import { DigitalDocumentPermission } from "../../store/models/DigitalDocumentPermission";
import DigitalDocumentPermissionType from "../../store/models/DigitalDocumentPermissionType";
import useCollections from "../../store/useCollections";
import { List } from "../../store/models/List";
import { Voter } from "../../store/models/voter/Voter";
import { Universe } from "../../store/models/Universe";
import UniverseField from "../universes/UniverseField";
import styles from "./NewList.module.css";
import { FeatureGroup, CircleMarker } from "react-leaflet";
import {
  FeatureGroup as LeafletFeatureGroup,
  LatLng,
  LayerGroup,
  Map,
  Polyline,
} from "leaflet";
import { VoterReference } from "../../store/models/voter/VoterReference";
import { GeoCoordinates } from "../../store/models/voter/GeoCoordinates";
import useLocationSearch from "../../core/hooks/useLocationSearch";
import GranteeType from "../../store/models/GranteeType";
import ListVoterFilter from "./ListVoterFilter";
import { IRequestOptions } from "@datx/jsonapi";
import AppCollection from "../../store/AppCollection";
import { SearchAggregation } from "../../store/models/SearchAggregation";
import { SearchMetaInformation } from "../../store/models/SearchMetaInformation";
import ModelVersion from "../../store/models/ModelVersion";
import CreativeWorkStatus from "../../store/models/CreativeWorkStatus";

type VoterMarker = {
  id: string;
  householdId: string;
  position: LatLng;
};

type LabeledLayer = {
  id: number;
  randomId: number;
  layer: Polyline;
};

type SelectedLayer = {
  id: number;
  label: string;
  markers: VoterMarker[];
  households: number;
};

function NewList() {
  const locationSearch = useLocationSearch();
  let searchUniverseId = locationSearch.get("universeId");
  const initialUniverseId = searchUniverseId ? searchUniverseId : undefined;

  const collections = useCollections();
  const accountInfo = useAccountInfo();
  const [loading, setLoading] = useState(false);
  const [votersLoaded, setVotersLoaded] = useState(0);
  const [listLabel, setListLabel] = useState("List");
  const [universe, setUniverse] = useState<Universe>();
  const [universeId, setUniverseId] = useState<string>();
  const [universeVoters, setUniverseVoters] = useState(0);
  const [listsCompleted, setListsCompleted] = useState(0);
  const [listsFailed, setListsFailed] = useState(0);
  const [filter, setFilter] = useState<Record<string, string | string[]>>({});
  const [excludeContacted, setExcludeContacted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);
  const [filterOpen, setFilterOpen] = useState(true);
  const [filterToggleIcon, setFilterToggleIcon] = useState("expand_more");
  const [processingOpen, setProcessingOpen] = useState(false);
  const [processingCompleted, setProcessingCompleted] = useState(false);
  const [markers, setMarkers] = useState<VoterMarker[]>([]);
  const [map, setMap] = useState<Map>();
  const [layers, setLayers] = useState<LabeledLayer[]>([]);
  const [selectedLayers, setSelectedLayers] = useState<SelectedLayer[]>([]);
  const [searchAggregations, setSearchAggregations] = useState<
    SearchAggregation[]
  >([]);

  const featureGroupRef = useRef<LeafletFeatureGroup>(null);
  const randomIdRef = useRef<number>(Math.floor(Math.random() * 8999 + 1000));

  const onDrawCreated = (layer: Polyline, layerId: number) => {
    const randomId = randomIdRef.current++;
    setLayers((currentLayers) => [
      ...currentLayers,
      {
        id: layerId,
        randomId: randomId,
        layer: layer,
      },
    ]);
  };

  const onDrawDeleted = (layerGroup: LayerGroup) => {
    const deletedLayers = layerGroup
      .getLayers()
      .map((layer) => layerGroup.getLayerId(layer));
    setLayers((currentLayers) =>
      currentLayers.filter((layer) => !deletedLayers.includes(layer.id))
    );
  };

  const onDrawEdited = (layerGroup: LayerGroup) => {
    setLayers((currentLayers) =>
      currentLayers.map((layer) => {
        const editedLayer = layerGroup.getLayer(layer.id);
        if (editedLayer) {
          layer.layer = editedLayer as Polyline;
        }
        return layer;
      })
    );
  };

  const onLabelChange = (label: string, layerId: number) => {
    const foundLayer = selectedLayers.find(
      (selectedLayer) => selectedLayer.id === layerId
    );
    if (foundLayer) {
      foundLayer.label = label;
    }
  };

  const getUserPermission = useCallback(() => {
    const userGrantee = new Grantee();
    userGrantee.id = accountInfo!.localAccountId;
    userGrantee.name = accountInfo!.username;
    userGrantee.granteeType = GranteeType.USER;

    const userPermission = new DigitalDocumentPermission();
    userPermission.grantee = userGrantee;
    userPermission.permissionType = DigitalDocumentPermissionType.WRITE;

    return userPermission;
  }, [accountInfo]);

  const saveLists = useCallback(async () => {
    setProcessingCompleted(false);
    setProcessingOpen(true);

    const permissions: DigitalDocumentPermission[] = [];

    universe?.permissions
      .filter((permission) => {
        return (
          permission.permissionType === DigitalDocumentPermissionType.READ &&
          permission.grantee.granteeType === GranteeType.GROUP
        );
      })
      .forEach((permission) => permissions.push(permission));

    const userPermission = getUserPermission();
    permissions.push(userPermission);

    const lists = selectedLayers.map((selectedLayer) => {
      const householdIds = new Set<string>();
      const voterReferences = selectedLayer.markers.map((voterMarker) => {
        const voterReference = new VoterReference();
        voterReference.id = voterMarker.id;
        const geo = new GeoCoordinates();
        geo.latitude = voterMarker.position.lat;
        geo.longitude = voterMarker.position.lng;
        voterReference.geo = geo;
        householdIds.add(voterMarker.householdId);
        return voterReference;
      });
      return new List({
        id: uuid(),
        version: ModelVersion.LIST,
        name: selectedLayer.label,
        description: selectedLayer.label,
        dateCreated: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        creativeWorkStatus: CreativeWorkStatus.PUBLISHED,
        permissions: permissions,
        universeId: universeId,
        voterReferences: voterReferences,
        totalVoterCount: voterReferences.length,
        totalHouseholdCount: householdIds.size,
      });
    });

    try {
      collections.add(lists);

      lists.forEach(async (list) => {
        try {
          await list.save();
          setListsCompleted((listsCompleted) => listsCompleted + 1);
        } catch (e) {
          setListsFailed((listsFailed) => listsFailed + 1);
        }
      });

      setProcessingCompleted(true);
    } catch (e) {
      lists.forEach((list) => collections.removeOne(list));
      setErrorTitle("Submit Failed");
      setErrorMessage("Processing failed");
      setProcessingOpen(false);
      setErrorOpen(true);
    } finally {
      setSubmitDisabled(false);
    }
  }, [collections, selectedLayers, universe, universeId, getUserPermission]);

  const onSubmitClick = () => {
    withAuthorization(saveLists, () => {
      setErrorTitle("Authorization Failed");
      setErrorMessage("Unable to get Access Token");
      setErrorOpen(true);
      setSubmitDisabled(false);
    });
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onProcessingDialogClose = () => {
    setProcessingOpen(false);
    setCompleted(true);
  };

  const onFilterToggleClick = () => {
    if (filterOpen) {
      setFilterOpen(false);
    } else {
      setFilterOpen(true);
    }
  };

  const onFilterChange = (filter: Record<string, string | string[]>) => {
    setFilter(filter);
  };

  const onUniversesFound = (universesFound: number) => {};

  const onUniverseChange = (
    listUniverseId: string,
    listUniverseVoters: number
  ) => {
    setUniverseId(listUniverseId);
    setUniverseVoters(listUniverseVoters);
  };

  const onExcludeContactedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExcludeContacted(event.target.checked);
  };

  const whenReady = (leafletMap: Map) => {
    setMap(leafletMap);
  };

  const getUniverseAuthorized = async (
    selectedUniverseId: string,
    appCollection: AppCollection
  ) => {
    try {
      const universeResponse = await appCollection.getOne(
        Universe,
        selectedUniverseId
      );

      const selectedUniverse = universeResponse.data as Universe;
      setUniverse(selectedUniverse);
      const groupPermission = selectedUniverse.permissions.find(
        (permission) => permission.grantee.granteeType === GranteeType.GROUP
      );
      if (groupPermission) {
        setListLabel(groupPermission.grantee.name);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Universe Loading Failed");
      setErrorMessage("Request Failed");
      setErrorOpen(true);
    }
  };

  const getVotersAggregationsAuthorized = async (
    options: IRequestOptions,
    appCollection: AppCollection
  ) => {
    try {
      setVotersLoaded(0);
      let response = await appCollection.getMany(Voter, options);
      if (Array.isArray(response.data)) {
        if (response.meta) {
          const searchMetaInformation = response.meta as SearchMetaInformation;
          setSearchAggregations(searchMetaInformation.searchAggregations);
        }
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Filters Loading Failed");
      setErrorMessage("Request Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const getVotersAuthorized = async (
    options: IRequestOptions,
    appCollection: AppCollection
  ) => {
    try {
      let response = await appCollection.getMany(Voter, options);
      const totalVoters = [];
      if (Array.isArray(response.data)) {
        if (response.meta) {
          const searchMetaInformation = response.meta as SearchMetaInformation;
          setSearchAggregations(searchMetaInformation.searchAggregations);
        }

        totalVoters.push(...response.data);
        setVotersLoaded(totalVoters.length);
        while (response.next) {
          response = await response.next();
          if (Array.isArray(response.data)) {
            totalVoters.push(...response.data);
            setVotersLoaded(totalVoters.length);
          }
        }
      }

      const voterMarkers = totalVoters.map((voter) => {
        return {
          id: voter.id,
          householdId: voter.person.homeLocation.address.id,
          position: new LatLng(
            voter.person.homeLocation.geo.latitude,
            voter.person.homeLocation.geo.longitude
          ),
        };
      });

      setMarkers(voterMarkers);
    } catch (e) {
      setMarkers([]);
    } finally {
      setLoading(false);
    }
  };

  const getVotersOptions = useCallback(
    (selectedUniverseId: string, pageLimit: number) => {
      const queryFilter = { ...filter };
      queryFilter["universeId"] = selectedUniverseId;
      queryFilter["targeting.status"] = "1";

      if (excludeContacted) {
        queryFilter["listId"] = "CONTACTED";
      }

      const options = {
        queryParams: {
          filter: queryFilter,
          custom: [
            {
              key: "page[limit]",
              value: pageLimit.toString(),
            },
          ],
        },
      };
      return options;
    },
    [filter]
  );

  const setTokenFailed = () => {
    setErrorTitle("Authorization Failed");
    setErrorMessage("Unable to get Access Token");
    setErrorOpen(true);
    setSubmitDisabled(false);
    setLoading(false);
  };

  const getVotersAggregations = useCallback(
    async (selectedUniverseId: string) => {
      setMarkers([]);
      setLoading(true);

      const options = getVotersOptions(selectedUniverseId, 1);
      withAuthorization(
        () => getVotersAggregationsAuthorized(options, collections),
        setTokenFailed
      );
    },
    [collections, getVotersOptions]
  );

  const getVoters = useCallback(
    async (selectedUniverseId: string, pageLimit: number) => {
      setVotersLoaded(0);
      setLoading(true);
      const options = getVotersOptions(selectedUniverseId, pageLimit);

      withAuthorization(
        () => getVotersAuthorized(options, collections),
        setTokenFailed
      );
    },
    [collections, getVotersOptions]
  );

  useEffect(() => {
    if (universeId) {
      withAuthorization(
        () => getUniverseAuthorized(universeId, collections),
        setTokenFailed
      );

      if (Object.keys(filter).length) {
        getVoters(universeId, 1000);
      } else {
        getVotersAggregations(universeId);
      }
    }
  }, [universeId, filter, collections, getVoters, getVotersAggregations]);

  useEffect(() => {
    if (filterOpen) {
      setFilterToggleIcon("expand_less");
    } else {
      setFilterToggleIcon("expand_more");
    }
  }, [filterOpen]);

  useEffect(() => {
    const bounds = featureGroupRef.current?.getBounds();
    if (bounds?.isValid()) {
      map?.fitBounds(bounds);
    }
  }, [markers, map]);

  useEffect(() => {
    const selectedMarkerIds = new Set<string>();
    const currentSelectedLayers: SelectedLayer[] = layers.map((layer) => {
      const bounds = layer.layer.getBounds();
      const filteredMarkers = markers.filter(
        (marker) => !selectedMarkerIds.has(marker.id)
      );
      const boundedMarkers = filteredMarkers.filter((marker) =>
        bounds.contains(marker.position)
      );
      const householdIds = new Set<string>();
      boundedMarkers.forEach((marker) => {
        selectedMarkerIds.add(marker.id);
        householdIds.add(marker.householdId);
      });
      return {
        id: layer.id,
        label: `${listLabel}-${layer.randomId}`,
        markers: boundedMarkers,
        households: householdIds.size,
      };
    });
    setSelectedLayers(currentSelectedLayers);
  }, [markers, layers, listLabel]);

  useEffect(() => {
    if (selectedLayers.length) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedLayers]);

  if (completed) {
    return <Redirect to="/lists" />;
  }

  return (
    <div>
      <Grid container>
        <Grid item lg={3} xs={12}>
          <UniverseField
            onUniversesFound={onUniversesFound}
            onUniverseChangeHandler={onUniverseChange}
            initialUniverseId={initialUniverseId}
          />
        </Grid>
        <Grid
          item
          lg={9}
          xs={12}
          component={Paper}
          variant="outlined"
          className={styles.filterStatusContainer}
        >
          <div className={styles.filterResults}>
            <div className={styles.totalMarkers}>
              {markers.length} of {universe?.voters}
            </div>
            <Icon className={styles.totalVoters} color="disabled">
              person
            </Icon>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  color="primary"
                  onChange={onExcludeContactedChange}
                />
              }
              className={styles.switch}
              label="Exclude Contacted"
              labelPlacement="start"
            />
          </div>
          <IconButton
            className={styles.expandButton}
            onClick={onFilterToggleClick}
          >
            <Icon>{filterToggleIcon}</Icon>
          </IconButton>
        </Grid>
      </Grid>

      <Collapse in={filterOpen}>
        <ListVoterFilter
          onFilterChange={onFilterChange}
          searchAggregations={searchAggregations}
        />
      </Collapse>

      <Grid container className={styles.gridContainer}>
        <Grid item lg={3} xs={12}>
          <Paper variant="outlined" className={styles.selectionContainer}>
            <Toolbar disableGutters>
              <IconButton disabled>
                <PrimaryAvatar>
                  <Icon>assignment</Icon>
                </PrimaryAvatar>
              </IconButton>
              <Typography variant="h6">New Lists</Typography>
            </Toolbar>
            <MaterialList dense>
              {selectedLayers.map((selectedLayer) => (
                <ListItem key={selectedLayer.id}>
                  <TextField
                    defaultValue={selectedLayer.label}
                    onChange={(event) =>
                      onLabelChange(event.target.value, selectedLayer.id)
                    }
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">
                            {selectedLayer.markers.length}
                          </Typography>
                          <Icon color="disabled" className={styles.voters}>
                            person
                          </Icon>
                          <Typography variant="caption">
                            {selectedLayer.households}
                          </Typography>
                          <Icon className={styles.households} color="disabled">
                            home
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItem>
              ))}
            </MaterialList>
          </Paper>
        </Grid>
        <Grid item lg={9} xs={12}>
          <PaperMap
            whenReady={whenReady}
            onDrawCreated={onDrawCreated}
            onDrawDeleted={onDrawDeleted}
            onDrawEdited={onDrawEdited}
          >
            <FeatureGroup ref={featureGroupRef}>
              {markers.map((marker) => (
                <CircleMarker
                  key={marker.id}
                  center={marker.position}
                  color="#002e6dff"
                  radius={5}
                />
              ))}
            </FeatureGroup>
          </PaperMap>
        </Grid>
      </Grid>
      <Toolbar disableGutters>
        <Grid container justifyContent="flex-end">
          <CancelButton buttonLink="/lists" />
          <SubmitButton disabled={submitDisabled} onClick={onSubmitClick} />
        </Grid>
      </Toolbar>

      <ProcessingDialog
        title="Loading"
        message={`Voters Loaded: ${votersLoaded} of ${universeVoters}`}
        open={loading}
        completed={processingCompleted}
        onClose={onProcessingDialogClose}
      />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      <ProcessingDialog
        title="Processing Lists"
        message={`Completed: ${listsCompleted} and Failed: ${listsFailed}`}
        open={processingOpen}
        completed={processingCompleted}
        onClose={onProcessingDialogClose}
      />
    </div>
  );
}

export default NewList;
