import React from "react";
import { Container, Grid } from "@material-ui/core";
import SignInMicrosoftButton from "../components/SignInMicrosoftButton";
import LogoContainer from "./LogoContainer";

function SignIn() {
  return (
    <Container maxWidth="xs">
      <Grid container justify="center">
        <LogoContainer />
        <SignInMicrosoftButton />
      </Grid>
    </Container>
  );
}

export default SignIn;
