import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import CreativeWorkStatus from "./CreativeWorkStatus";
import { DigitalDocumentPermission } from "./DigitalDocumentPermission";
import { QuantitativeValue } from "./QuantitativeValue";

export class Universe extends jsonapi(Model) {
  static type = "universes";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public name!: string;

  @Attribute()
  public description!: string;

  @Attribute()
  public dateCreated!: Date;

  @Attribute()
  public dateModified!: Date;

  @Attribute()
  public creativeWorkStatus!: CreativeWorkStatus;

  @Attribute()
  public voters!: number;

  @Attribute()
  public households!: number;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;

  @Attribute()
  public quantitativeValues!: Array<QuantitativeValue>;
}
