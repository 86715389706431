import React, { useEffect, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { observable } from "mobx";
import useLocationSearch from "../../core/hooks/useLocationSearch";
import UserCard from "./UserCard";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import ApiPermission from "../../security/ApiPermission";
import { getGroupMembers } from "../../services/GraphService";
import { ApplicationUser } from "../../store/models/ApplicationUser";
import AccountStatus from "../../store/models/AccountStatus";
import styles from "./Users.module.css";
import CampaignField from "./CampaignField";

function Users() {
  const locationSearch = useLocationSearch();
  let searchGroupId = locationSearch.get("groupId");
  const initialGroupId = searchGroupId ? searchGroupId : undefined;

  const [users, setUsers] = useState<ApplicationUser[]>(
    observable<ApplicationUser>([])
  );
  const [groupId, setGroupId] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  useEffect(() => {
    if (groupId) {
      setUsers([]);
      setLoading(true);
      const promise = getGroupMembers(groupId, search);
      promise
        .then((groupMembers) => {
          const applicationUsers = groupMembers.map((user) => {
            const username = user.mail ? user.mail : user.otherMails?.pop();

            const enabledStatus =
              user.accountEnabled === true
                ? AccountStatus.ENABLED
                : AccountStatus.DISABLED;
            const accountStatus =
              user.externalUserState === AccountStatus.PENDING_ACCEPTANCE
                ? AccountStatus.PENDING_ACCEPTANCE
                : enabledStatus;

            const applicationUser = new ApplicationUser();
            applicationUser.displayName = user.displayName!;
            applicationUser.accountStatus = accountStatus;
            applicationUser.id = user.id!;
            applicationUser.username = username!;
            applicationUser.created = user.createdDateTime!;
            return applicationUser;
          });
          setUsers(applicationUsers);
          setLoading(false);
        })
        .catch((error) => {
          setErrorStatusCode(error.statusCode);
          setErrorTitle("Loading Failed");
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("Processing Failed");
          }
          setErrorOpen(true);
        })
        .finally(() => setLoading(false));
    }
  }, [groupId, search]);

  const onCampaignChange = (campaignGroupId: string) => {
    setGroupId(campaignGroupId);
  };

  const onGroupsFound = (groupsFound: number) => {
    if (groupsFound === 0) {
      setLoading(false);
    }
  };

  const onSearchChangeHandler = (search: string) => {
    setSearch(search);
  };

  return (
    <div>
      <SearchBar
        addButtonLink={"/users/new"}
        total={users.length}
        onSearchChangeHandler={onSearchChangeHandler}
        addApiPermissionRequired={ApiPermission.USER_INVITE_ALL}
      />
      <div className={styles.campaignField}>
        <CampaignField
          onCampaignChangeHandler={onCampaignChange}
          onGroupsFound={onGroupsFound}
          initialGroupId={initialGroupId}
        />
      </div>
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      {users.map((user) => (
        <UserCard
          key={user.id}
          title={user.displayName}
          username={user.username}
          accountStatus={user.accountStatus}
          userId={user.id}
          created={user.created}
        />
      ))}
    </div>
  );
}

export default Users;
