import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  IconButton,
  SvgIcon,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ReactComponent as BrandIcon } from "../images/brand.svg";
import AccountMenu from "./AccountMenu";
import useApplicationHeading from "./hooks/useApplicationHeading";
import styles from "./HeaderAppBar.module.css";

function HeaderAppBar() {
  const applicationHeading = useApplicationHeading();

  return (
    <AppBar position="fixed" className={styles.headerAppBarContainer}>
      <Toolbar disableGutters>
        <IconButton component={Link} to="/">
          <SvgIcon component={BrandIcon} />
        </IconButton>
        <Typography variant="h5" component="h1" className={styles.headerLabel}>
          {applicationHeading}
        </Typography>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
}

export default HeaderAppBar;
