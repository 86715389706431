import { Collection } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import { Campaign } from "./models/Campaign";
import { List } from "./models/List";
import { ReplyAction } from "./models/ReplyAction";
import { Survey } from "./models/Survey";
import { Universe } from "./models/Universe";
import { Voter } from "./models/voter/Voter";

export default class AppCollection extends jsonapi(Collection) {
  public static types = [Campaign, List, ReplyAction, Survey, Universe, Voter];
}
