import { IRequestOptions } from "@datx/jsonapi";

export function getRequestOptions(
  search?: string,
  granteeId?: string
): IRequestOptions {
  const filter: Record<string, string> = {};
  if (search) {
    filter["name"] = search;
  }
  if (granteeId) {
    filter["permissions.grantee.id"] = granteeId;
  }

  const queryParams = {
    sort: "-dateCreated",
    filter: filter,
    custom: [
      {
        key: "page[limit]",
        value: "1000",
      },
    ],
  };

  const options = {
    queryParams: queryParams,
  };
  return options;
}
