import React from "react";
import {
  FormControl,
  Icon,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { SearchTerm } from "../../store/models/SearchTerm";
import styles from "./SearchTermSelect.module.css";

const EMPTY = "";
const NONE = <em>None</em>;

type Props = {
  label: string;
  value: string | string[];
  searchTerms: SearchTerm[];
  onValueChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  multiple?: boolean;
};

function SearchTermSelect({
  label,
  value,
  searchTerms,
  onValueChange,
  multiple = false,
}: Props) {
  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onValueChange(event);
  };

  const renderValue = (selected: any) => {
    if (Array.isArray(selected)) {
      return <span className={styles.selectedLength}>{selected.length}</span>;
    } else {
      return selected;
    }
  };

  const noneItem = multiple ? undefined : (
    <MenuItem value={EMPTY} className={styles.menuItemText}>
      {NONE}
    </MenuItem>
  );

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        multiple={multiple}
        renderValue={renderValue}
        onChange={onChange}
      >
        {noneItem}
        {searchTerms.map((searchTerm) => (
          <MenuItem key={searchTerm.term} value={searchTerm.term}>
            <div className={styles.menuItemText}>
              <div>{searchTerm.term}</div>
              <div className={styles.secondaryText}>
                {searchTerm.count}
                <Icon className={styles.voters} color="disabled">
                  person
                </Icon>
              </div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SearchTermSelect;
