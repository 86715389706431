import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import msalConfiguration from "./security/msalConfiguration";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./core/App";
import AppTheme from "./styles/AppTheme";
import SignIn from "./core/SignIn";
import { CollectionProvider } from "./store/CollectionProvider";

const publicClientApplication = new PublicClientApplication(msalConfiguration);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <CollectionProvider>
        <BrowserRouter>
          <MsalProvider instance={publicClientApplication}>
            <AuthenticatedTemplate>
              <App />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <SignIn />
            </UnauthenticatedTemplate>
          </MsalProvider>
        </BrowserRouter>
      </CollectionProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
