import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";
import { IPublicClientApplication } from "@azure/msal-browser";

function onClick(application: IPublicClientApplication) {
  application.loginRedirect();
}

function SignInMicrosoftButton() {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => onClick(instance)}
      fullWidth
    >
      Sign In with Microsoft
    </Button>
  );
}

export default SignInMicrosoftButton;
