import React, { useCallback, useEffect, useState, ChangeEvent } from "react";
import { RouteComponentProps } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Chip,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Icon,
  TextField,
  Grid,
} from "@material-ui/core";
import { Group } from "@microsoft/microsoft-graph-types";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import {
  getGroup,
  getGroupMembersCount,
  updateGroup,
} from "../../services/GraphService";
import styles from "./Campaign.module.css";

type MatchParams = {
  id: string;
};

const DISABLED_DESCRIPTION = "DISABLED";

function Campaign({ match }: RouteComponentProps<MatchParams>) {
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState<Group>();
  const [groupMembersCount, setGroupMembersCount] = useState(0);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);
  const [enabled, setEnabled] = useState(false);

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked);
  };

  const getDisabledLabel = () => {
    return DISABLED_DESCRIPTION;
  };

  const onSubmitClick = async () => {
    setGroup(undefined);
    setLoading(true);

    const mailNickname = enabled ? group?.id : getDisabledLabel();

    const updatedGroup: Group = {
      id: group?.id,
      mailNickname: mailNickname,
    };
    try {
      await updateGroup(updatedGroup);
      loadGroup(match.params.id);
    } catch (error: any) {
      setErrorStatusCode(error["statusCode"]);
      setErrorTitle("Update Failed");
      if (error["message"]) {
        setErrorMessage(error["message"]);
      } else {
        setErrorMessage("Processing Failed");
      }
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const submitDisabled = () => {
    let disabled = false;
    if (group?.mailNickname === getDisabledLabel()) {
      disabled = !enabled;
    } else {
      disabled = enabled;
    }
    return disabled;
  };

  const loadGroup = useCallback((id: string) => {
    setLoading(true);
    getGroup(id)
      .then((foundGroup) => {
        setGroup(foundGroup);
        setEnabled(foundGroup.mailNickname !== getDisabledLabel());
      })
      .catch((error) => {
        if (error.statusCode === 404) {
          setGroup(undefined);
        } else {
          setErrorStatusCode(error.statusCode);
          setErrorTitle("Loading Failed");
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("Processing Failed");
          }
          setErrorOpen(true);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadGroup(match.params.id);
  }, [match.params.id, loadGroup]);

  useEffect(() => {
    if (group) {
      getGroupMembersCount(group.id!)
        .then((count) => setGroupMembersCount(count))
        .catch((error) => {
          setErrorStatusCode(error.statusCode);
          setErrorTitle("Loading Failed");
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("Processing Failed");
          }
          setErrorOpen(true);
        });
    }
  }, [group]);

  let card = <Card></Card>;
  if (group) {
    card = (
      <Card>
        <CardHeader
          avatar={<PrimaryAvatar></PrimaryAvatar>}
          title={group.displayName}
          titleTypographyProps={{
            variant: "h6",
          }}
          subheader={
            <div className={styles.subheader}>
              <Chip
                label={`Created ${group.createdDateTime}`}
                className={styles.chip}
                size="small"
                variant="outlined"
                icon={<Icon fontSize="inherit">schedule</Icon>}
              />
              <Chip
                label={groupMembersCount}
                className={styles.chip}
                size="small"
                variant="outlined"
                icon={<Icon fontSize="inherit">account_circle</Icon>}
              />
            </div>
          }
        />
        <CardContent>
          <TextField
            variant="outlined"
            fullWidth
            disabled
            label="Description"
            value={group.description}
          />
          <FormControl component="fieldset" className={styles.checkboxField}>
            <FormControlLabel
              label="Enabled"
              disabled={loading}
              control={
                <Checkbox
                  color="primary"
                  name="enabled"
                  checked={enabled}
                  onChange={onEnabledChange}
                />
              }
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              startIcon={<Icon>account_circle</Icon>}
              component={RouterLink}
              to={`/users?groupId=${group.id}`}
            >
              Users
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={styles.submitButton}
              disabled={submitDisabled()}
              onClick={onSubmitClick}
            >
              Submit
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }

  return (
    <div>
      {card}
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
    </div>
  );
}

export default Campaign;
