import { useLocation } from "react-router-dom";

const sectionPattern = new RegExp("^/([^/]+)/?");

const getSectionHeading = (pathname: string) => {
  const sectionMatcher = sectionPattern.exec(pathname);
  let sectionHeading = undefined;
  if (sectionMatcher) {
    sectionHeading = sectionMatcher[1];
    sectionHeading =
      sectionHeading.charAt(0).toUpperCase() + sectionHeading.slice(1);
  }
  return sectionHeading;
};

const useSectionHeading = () => {
  const location = useLocation();
  return getSectionHeading(location.pathname);
};

export default useSectionHeading;
