import React, { useCallback, useEffect, useState, ChangeEvent } from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import SearchBar from "../../components/SearchBar";
import { List } from "../../store/models/List";
import useCollections from "../../store/useCollections";
import { observable } from "mobx";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import LoadingProgress from "../../components/LoadingProgress";
import withAuthorization from "../../security/withAuthorization";
import ListCard from "./ListCard";
import AppRole from "../../security/AppRole";
import useLocationSearch from "../../core/hooks/useLocationSearch";
import { getRequestOptions } from "../../services/ResourceSearchService";
import styles from "./Lists.module.css";
import CampaignField from "../users/CampaignField";

function Lists() {
  const locationSearch = useLocationSearch();
  let searchGroupId = locationSearch.get("groupId");
  const initialGroupId = searchGroupId ? searchGroupId : undefined;

  const collections = useCollections();
  const [search, setSearch] = useState<string>();
  const [groupId, setGroupId] = useState<string>();
  const [lists, setLists] = useState<List[]>(observable<List>([]));
  const [loading, setLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const onSearchChangeHandler = (requestedSearch: string) => {
    if (requestedSearch.length) {
      setSearch(requestedSearch);
    } else {
      setSearch(undefined);
    }
  };

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onCampaignChange = (campaignGroupId: string) => {
    setGroupId(campaignGroupId);
  };

  const onGroupsFound = (groupsFound: number) => {
    if (groupsFound === 0) {
      setLoading(false);
    }
  };

  const onChange = async (list: List) => {
    try {
      await list.save();
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Save Failed");
      setErrorMessage(`List Status update failed`);
      setErrorOpen(true);
    }
  };

  const onShowArchivedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(event.target.checked);
  };

  const getModels = useCallback(async () => {
    setLoading(true);
    try {
      const options = getRequestOptions(search, groupId);

      if (!showArchived) {
        const statusFilter = {
          key: "filter[creativeWorkStatus][NEQ]",
          value: "ARCHIVED",
        };
        options.queryParams?.custom?.push(statusFilter);
      }

      const response = await collections.getMany(List, options);
      if (Array.isArray(response.data)) {
        setLists(response.data);
      }
    } catch (error: any) {
      setErrorStatusCode(error["status"]);
      setErrorTitle("Loading Failed");
      setErrorMessage("Processing Failed");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }, [collections, search, groupId, showArchived]);

  const loadModels = useCallback(async () => {
    if (groupId) {
      withAuthorization(getModels, () => {
        setErrorTitle("Authorization Failed");
        setErrorMessage("Unable to get Access Token");
        setErrorOpen(true);
      });
    }
  }, [getModels, groupId]);

  useEffect(() => {
    loadModels();
  }, [loadModels]);

  return (
    <div>
      <SearchBar
        addButtonLink={"/lists/new"}
        total={lists.length}
        onSearchChangeHandler={onSearchChangeHandler}
        addAppRoleRequired={AppRole.LIST_READ_WRITE}
      />
      <Grid container className={styles.campaignField}>
        <Grid item xl={11} lg={10} sm={9} xs={12}>
          <CampaignField
            onCampaignChangeHandler={onCampaignChange}
            onGroupsFound={onGroupsFound}
            initialGroupId={initialGroupId}
          />
        </Grid>
        <Grid
          item
          container
          xl={1}
          lg={2}
          sm={3}
          xs={12}
          className={styles.itemArchived}
        >
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                onChange={onShowArchivedChange}
              />
            }
            label="Show Archived"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <LoadingProgress loading={loading} />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
      {lists.map((list) => (
        <ListCard key={list.id} list={list} onChange={onChange} />
      ))}
    </div>
  );
}

export default Lists;
