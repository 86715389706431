import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Icon,
  Link,
} from "@material-ui/core";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import ApiPermissionGranted from "../../security/ApiPermissionGranted";
import ApiPermission from "../../security/ApiPermission";
import styles from "./CampaignCard.module.css";
import ScheduleChip from "../../components/ScheduleChip";

type Props = {
  title: string;
  created: string;
  groupId: string;
  disabled: boolean;
};

function CampaignCard(props: Props) {
  return (
    <Card className={styles.campaignCard}>
      <CardHeader
        title={
          <Link component={RouterLink} to={`/campaigns/${props.groupId}`}>
            {props.title}
          </Link>
        }
        subheader={<ScheduleChip label="Created" dateTime={props.created} />}
        avatar={
          <Link component={RouterLink} to={`/campaigns/${props.groupId}`}>
            {props.disabled ? (
              <Avatar>
                <Icon>campaign</Icon>
              </Avatar>
            ) : (
              <PrimaryAvatar>
                <Icon>campaign</Icon>
              </PrimaryAvatar>
            )}
          </Link>
        }
      />
      <CardActions>
        <div className={styles.actionsContainer}>
          <Button
            variant="outlined"
            startIcon={<Icon>assignment</Icon>}
            component={RouterLink}
            to={`/lists?groupId=${props.groupId}`}
          >
            Lists
          </Button>
          <Button
            variant="outlined"
            startIcon={<Icon>account_circle</Icon>}
            component={RouterLink}
            to={`/users?groupId=${props.groupId}`}
          >
            Users
          </Button>
        </div>
        <ApiPermissionGranted apiPermission={ApiPermission.USER_INVITE_ALL}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/users/new?groupId=${props.groupId}`}
            disabled={props.disabled}
          >
            New User
          </Button>
        </ApiPermissionGranted>
      </CardActions>
    </Card>
  );
}

export default CampaignCard;
