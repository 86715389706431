import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
  TextField,
  Toolbar,
  Typography,
  Icon,
  IconButton,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { setVoters } from "../../services/ResourceService";
import CancelButton from "../../components/buttons/CancelButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import PrimaryAvatar from "../../components/PrimaryAvatar";
import CampaignField from "../users/CampaignField";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import ControlledFieldValue from "../../components/ControlledFieldValue";
import ProcessingDialog from "../../components/dialogs/ProcessingDialog";
import withAuthorization from "../../security/withAuthorization";
import styles from "./NewUniverse.module.css";
import { Grantee } from "../../store/models/Grantee";
import GranteeType from "../../store/models/GranteeType";

function NewUniverse() {
  const [file, setFile] = useState<File>();
  const [groupId, setGroupId] = useState<string>();
  const [groupName, setGroupName] = useState<string>();
  const [processingOpen, setProcessingOpen] = useState(false);
  const [processingCompleted, setProcessingCompleted] = useState(false);
  const [name, setName] = useState<string>();
  const [completed, setCompleted] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [filename, setFilename] = useState<string>(
    ControlledFieldValue.DEFAULT
  );
  const [fileSize, setFileSize] = useState(0);
  const [votersProcessed, setVotersProcessed] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorStatusCode, setErrorStatusCode] = useState(0);

  const getFileSize = (bytes: number) => {
    const kilobytes = bytes / 1024;
    return kilobytes.toFixed(2);
  };

  const getFileStatus = (bytes: number) => {
    let fileStatus = undefined;

    if (bytes) {
      fileStatus = `${getFileSize(bytes)} KB`;
    }

    return fileStatus;
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onCampaignChange = (
    campaignGroupId: string,
    campaignGroupName: string
  ) => {
    setGroupId(campaignGroupId);
    setGroupName(campaignGroupName);
  };

  const onGroupsFound = (groupsFound: number) => {};

  const onErrorDialogClose = () => {
    setErrorOpen(false);
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileSelected = event.target.files[0];
      if (fileSelected) {
        setFile(fileSelected);
        setFilename(fileSelected.name);
        setFileSize(fileSelected.size);
      }
    }
  };

  const onProcessingDialogClose = () => {
    setProcessingOpen(false);
    setCompleted(true);
  };

  const getGroupGrantee = useCallback(() => {
    const groupGrantee = new Grantee();
    if (groupId && groupName) {
      groupGrantee.id = groupId;
      groupGrantee.name = groupName;
      groupGrantee.granteeType = GranteeType.GROUP;
    }
    return groupGrantee;
  }, [groupId, groupName]);

  const saveResources = useCallback(
    async (accessToken) => {
      try {
        setProcessingOpen(true);

        if (file && name) {
          setProcessingCompleted(false);
          const groupGrantee = getGroupGrantee();
          const universe = await setVoters(
            accessToken,
            file,
            name,
            groupGrantee
          );
          setVotersProcessed(universe.voters);
          setProcessingCompleted(true);
        } else {
          setErrorTitle("Submit Failed");
          setErrorMessage("Missing required fields");
          setProcessingOpen(false);
          setSubmitDisabled(false);
          setErrorOpen(true);
        }
      } catch (error: any) {
        setErrorStatusCode(error["status"]);
        setErrorTitle("Submit Failed");
        setErrorMessage("Processing failed");
        setProcessingOpen(false);
        setSubmitDisabled(false);
        setErrorOpen(true);
      }
    },
    [name, file, getGroupGrantee]
  );

  const onSubmitClick = () => {
    withAuthorization(saveResources, () => {
      setErrorTitle("Authorization Failed");
      setErrorMessage("Unable to get Access Token");
      setErrorOpen(true);
      setSubmitDisabled(false);
    });
  };

  useEffect(() => {
    if (name?.length && file?.size) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [name, file]);

  if (completed) {
    return <Redirect to="/universes" />;
  }

  let processingMessage = "Loading...";
  if (processingCompleted) {
    processingMessage = `Voters Processed: ${votersProcessed}`;
  }

  return (
    <div>
      <Toolbar disableGutters>
        <IconButton disabled>
          <PrimaryAvatar>
            <Icon>groups</Icon>
          </PrimaryAvatar>
        </IconButton>
        <Typography variant="h6">New Universe</Typography>
      </Toolbar>
      <form autoComplete="off">
        <div className={styles.field}>
          <CampaignField
            onCampaignChangeHandler={onCampaignChange}
            onGroupsFound={onGroupsFound}
          />
        </div>
        <TextField
          label="Comma-Separated Values"
          variant="outlined"
          fullWidth
          value={filename}
          className={styles.field}
          helperText={getFileStatus(fileSize)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="upload">
                  <input
                    className={styles.fileInput}
                    id="upload"
                    name="upload"
                    type="file"
                    accept="text/csv"
                    onChange={onFileChange}
                  />
                  <IconButton component="span">
                    <Icon>upload</Icon>
                  </IconButton>
                </label>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          onChange={onNameChange}
          className={styles.field}
        />
      </form>
      <Toolbar disableGutters>
        <Grid container justifyContent="flex-end">
          <CancelButton buttonLink="/universes" />
          <SubmitButton disabled={submitDisabled} onClick={onSubmitClick} />
        </Grid>
      </Toolbar>
      <ProcessingDialog
        title="Processing Rows"
        message={processingMessage}
        open={processingOpen}
        completed={processingCompleted}
        onClose={onProcessingDialogClose}
      />
      <ErrorDialog
        open={errorOpen}
        title={errorTitle}
        message={errorMessage}
        statusCode={errorStatusCode}
        onClose={onErrorDialogClose}
      />
    </div>
  );
}

export default NewUniverse;
