import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { Button, Icon, IconButton, InputBase, Paper } from "@material-ui/core";
import styles from "./SearchBar.module.css";
import AppRole from "../security/AppRole";
import AppRoleGranted from "../security/AppRoleGranted";
import ApiPermission from "../security/ApiPermission";
import ApiPermissionGranted from "../security/ApiPermissionGranted";

interface Props {
  addButtonLink: string;
  total?: number;
  onSearchChangeHandler?: (search: string) => void;
  addAppRoleRequired?: AppRole;
  addApiPermissionRequired?: ApiPermission;
}

function SearchBar({
  addButtonLink,
  total = 0,
  onSearchChangeHandler = () => {},
  addAppRoleRequired,
  addApiPermissionRequired,
}: Props) {
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchChangeHandler(event.target.value);
  };

  let granted = undefined;

  if (addApiPermissionRequired) {
    granted = (
      <ApiPermissionGranted apiPermission={addApiPermissionRequired}>
        <IconButton aria-label="add" component={Link} to={addButtonLink}>
          <Icon color="primary">add_circle</Icon>
        </IconButton>
      </ApiPermissionGranted>
    );
  } else if (addAppRoleRequired) {
    granted = (
      <AppRoleGranted appRole={addAppRoleRequired}>
        <IconButton aria-label="add" component={Link} to={addButtonLink}>
          <Icon color="primary">add_circle</Icon>
        </IconButton>
      </AppRoleGranted>
    );
  }

  return (
    <Paper component="form" className={styles.searchBar}>
      <IconButton aria-label="search">
        <Icon>search</Icon>
      </IconButton>
      <InputBase
        placeholder="Search"
        className={styles.searchField}
        onChange={onSearchChange}
      />
      <Button disabled>{total}</Button>
      {granted}
    </Paper>
  );
}

export default SearchBar;
