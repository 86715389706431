import React, { PropsWithChildren } from "react";
import ApiPermission from "./ApiPermission";
import useApiPermissionGranted from "./useApiPermissionGranted";

type ApiPermissionRequired = {
  apiPermission: ApiPermission;
};

type ApiPermissionRequiredProps = PropsWithChildren<ApiPermissionRequired>;

function ApiPermissionGranted({
  apiPermission,
  children,
}: ApiPermissionRequiredProps): React.ReactElement | null {
  const granted = useApiPermissionGranted(apiPermission);

  if (granted) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
}

export default ApiPermissionGranted;
