import AccountStatus from "./AccountStatus";

export class ApplicationUser {
  public id!: string;

  public displayName!: string;

  public username!: string;

  public accountStatus!: AccountStatus;

  public created!: string;
}
