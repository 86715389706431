import msalConfiguration from "./msalConfiguration";
import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "@datx/jsonapi";

const application = new PublicClientApplication(msalConfiguration);
const scopes = [`${msalConfiguration.auth.clientId}/.default`];

function withAuthorization(
  onAccessToken: (accessToken: string) => void,
  onError: () => void
) {
  const accounts = application.getAllAccounts();
  const account = accounts.pop();
  if (account) {
    const request = {
      account: account,
      scopes: scopes,
    };
    const acquireTokenPromise = application.acquireTokenSilent(request);
    acquireTokenPromise
      .then((authenticationResult) => {
        config.defaultFetchOptions = {
          headers: {
            Authorization: `Bearer ${authenticationResult.accessToken}`,
            Accept: "application/vnd.api+json;charset=utf-8",
            "Content-Type": "application/vnd.api+json;charset=utf-8",
          },
        };
        onAccessToken(authenticationResult.accessToken);
      })
      .catch(onError);
  } else {
    onError();
  }
}

export default withAuthorization;
