import { Model, Attribute } from "@datx/core";
import { jsonapi } from "@datx/jsonapi";
import { DigitalDocumentPermission } from "./DigitalDocumentPermission";

export class ListSurveyReference extends jsonapi(Model) {
  static type = "list-survey-references";

  @Attribute({ isIdentifier: true })
  public id!: string;

  @Attribute()
  public listId!: string;

  @Attribute()
  public surveyId!: string;

  @Attribute()
  public permissions!: Array<DigitalDocumentPermission>;
}
