import React from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "./LoadingProgress.module.css";

interface Props {
  loading: boolean;
}

function LoadingProgress(props: Props) {
  if (props.loading) {
    return (
      <div className={styles.progressContainer}>
        <CircularProgress />
      </div>
    );
  } else {
    return null;
  }
}

export default LoadingProgress;
